
export const TabOption = {
  MON: { key: 'monitor', title: 'Monitor', path: '/trackerv2/monitoring' },
  REP: { key: 'report', title: 'Reports', path: '/trackerv2/report' },
  GEO: { key: 'geofence', title: 'Geofence', path: '/trackerv2/geofence' }
}

export const TabName = {
  MON: "monitor",
  REP: "report",
  GEO: "geofance"
}

export const GeofenceColors = [
  'rgba(65, 131, 215, 0.4)',
  'rgba(235, 59, 145, 0.4)',
  'rgba(103, 200, 77, 0.5)',
  'rgba(67, 19, 125, 0.35)',
  'rgba(241, 145, 53, 0.45)'
];


export const Perm = {
  UPDATE_HOUR: 'update_hour'
}

// for table
export function MonitorEntity(data) {
  data = data || {}

  let device = data.devices ? data.devices[0] : {}
  device = device || {}

  return {
    id: data.teltonika_id,
    name: data.name,
    device_id: device.device_id,
    imei: device.imei,
    longitude: data.longitude,
    latitude: data.latitude
  }
}





export function DeviceDetailEntity(data) {
  data = data || {}

  data.device = data.device || {}
  data.genset = data.genset || {}
  data.organization = data.organization || {}
  data.network = data.network || {}
  data.meta = data.meta || {}

  let current = data.meta.current || [0, 0, 0]
  current = current.map(val => parseInt(val) || 0).join(', ')

  let neutral = data.meta.neutral || [0, 0, 0]
  neutral = neutral.map(val => parseInt(val) || 0).join(', ')

  let lines = data.meta.lines || [0, 0, 0]
  lines = lines.map(val => parseInt(val) || 0).join(', ')

  let watt = data.meta.watt || [0, 0, 0]
  watt = watt.map(val => parseInt(val) || 0).join(', ')

  let main_power = data.meta.main_power || [0, 0, 0]
  main_power = main_power.map(val => parseInt(val) || 0).join(', ')

  return {
    device: {
      id: data.device.id,
      imei: data.device.imei,
      name: data.device.name || 'Device not found',
      status: data.device.status || 'off',
      longitude: data.device.longitude,
      latitude: data.device.latitude
    },
    genset: {
      id: data.genset.id,
      name: data.genset.name,
      geofence: data.genset.geofence,
      geofence_status: data.genset.geofence_status,
      geofence_color: data.genset.geofence_color || GeofenceColors[1],
      status: data.genset.status
    },
    organization: {
      id: data.organization.id,
      name: data.organization.name || ''
    },
    network: {
      id: data.network.id,
      name: data.network.name || ''
    },
    meta: {
      priority: data.meta.priority || 0,
      angle: data.meta.angle || 0,
      satelites: data.meta.fuel_level || 0,
      speed: data.meta.vol_alt || 0,
      eventid: data.meta.engine_speed || 0,
      avl239: data.meta.avl239 || 0,
      avl283: data.meta.avl283 || 0,
      avl284: data.meta.avl284 || 0,
      avl449: data.meta.avl449 || 0,
      avl1: data.meta.avl1 || 0,
      timestamp: data.meta.timestamp,

      uptime: data.meta.uptime || 0,
      additional_uptime: data.meta.add_uptime || 0,
      all_opt_time: data.meta.total_all_operate || 0,
      additional_all_opt_time: data.meta.add_opt_time || 0,
    },

  }
}

// for table
export function GeoEntity(data) {
  let device = data.devices[0] || {}

  return {
    id: data.genset_id,
    name: data.name,
    geofence: data.coordinate || [],
    location: `${data.latitude}, ${data.longitude}`,
    latitude: data.latitude,
    longitude: data.longitude,
    devices: data.devices,
    device,
    status: data.device_status
  }
}


export function GeoDevicesEntity(data) {
  return {
    device_id: data.device_id,
    device_unit_id: data.device_id,
    description: data.device_name,
    imei: data.uuid || data.imei
  }
}


export function GeneratorGeoEntity(data) {
  return {
    id: data.genset_id,
    name: data.name,
    devices: data.devices || [],
    description: data.description,
    location: data.location,
    latitude: data.latitude,
    longitude: data.longitude,
    coordinate: data.coordinate
  }
}


export function MainReportEntity(data) {
  data = data || {
    genset: {},
    device: {},
    network: {},
    meta: {}
  }

  const statuses = {
    '0': 'idle',
    '1': 'on',
    '-1': 'off',
    '2': 'out'
  }

  return {
    name: data.genset.name || 'Generator Unknown',
    device_id: data.device.id || '',
    imei: data.device.imei || '',
    sim: data.device.sim_number || '',
    network_name: data.network.name || '',
    battery: data.meta.battery || 0,
    fuel: data.meta.fuel || 0,
    oil: data.meta.oil || 0,
    temperature: data.meta.temperature || 0,
    charge_volt: data.meta.charge_alt_vol || 0,
    speed: data.meta.speed || 0,
    generator_freq: data.meta.generator_freq || 0,
    status: statuses[`${data.genset.status}`] || 'off'
  }
}


export function GraphReportEntity(data) {
  data = data || {}

  return {
    fuel: data.fuel || [],
    speed: data.speed || [],
    oil: data.oil || [],
    freq: data.freq || [],
    battery: data.battery || [],
    alt_volt: data.alt_volt || [],
    temperature: data.temperature || [],
    operate: data.operate || []
  }
}