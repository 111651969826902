<template lang="pug">
div#monitoring
  card(title='Satellites')
    .d-flex.mb-4
      div.w-100.pr-4
        matador-search#search-button(
          @input="onSearch" 
          placeholder='Search' 
          autocomplete='off'
        )

    loader(:loading="loading")
      p.text-center(v-if="vehicles.length == 0") No data available
      .row
        .col-12.col-md-4(
          v-for="(vehicle, index) in vehicles" 
          :key="vehicle.vehicle_id" 
          @mouseenter="hoveredDevice = vehicle"
        )
          satellite-list-card(
            :vehicle="vehicle"
            @confirm="confirm"
            @followVehicle="onFollowVehicle"
          )
          
      .pt-4.mt-3
        .d-flex.align-item-center.justify-content-between
          b-pagination(
            v-model="page"
            :total-rows="totalItem"
            :per-page="perPage"
            aria-controls="my-table"
          )
          //- div.d-flex.align-items-center
          //-   span.mr-2 Per&nbsp;Page: 
          //-   b-dropdown(:text='perPage.toString()' variant='light')
          //-     b-dropdown-item(@click="perPage = 12") 12
          //-     b-dropdown-item(@click="perPage = 48") 48
          //-     b-dropdown-item(@click="perPage = 96") 96
          //-     b-dropdown-item(@click="perPage = 184") 184

</template>
<script>
import DropdownMonitoring from "@/components/DropdownMonitoring.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";

import SatelliteListCard from '../components/SatelliteListCard.vue'

import Api from '@/utils/api'
import entity from '../entity'

export default {

  components: {
    DropdownMonitoring,
    ChevronDown,
    ChevronUp,
    ConfirmationModal,

    SatelliteListCard
  },

  data() {
    return {
      interval: null,
      
      totalItem: 0,
      perPage: 12,
      page: 1,
      
      loading: false,
      showVehicleAdd: false,

      showStatusModal:false,
      
      willResetVehicle: null,
      hoveredDevice: null,
      
      selectedVehicle: null,
      search: '',
      vehicles: [],
    }  
  },

  watch: {
    
    page: {
      handler() {
        this.getMonitoring()
      }
    },

    perPage: {
      handler(number, old) {
        if(number != old){
          this.page = 1;
        }
        this.getMonitoring()
      }
    },

  },

  methods: {

    
    confirm(type, vehicle) {
      if (type === 'reset') {
        this.willResetVehicle = vehicle
      } else if (type === 'switch')  {
        this.showStatusModal = true
        this.hoveredDevice = vehicle
      }
    },


    onSearch(value) {
      this.search = value;
      this.getMonitoring()
    },


    noop(){
      this.willResetVehicle = null;
    },

    
    getMonitoring() {
      if (this.loading) return

      this.loading = true

      let query = {
        q: this.search,
        status: this.status,
        page: this.page,
        limit: this.perPage,
        sort: 'name.asc'
      }

      const responseHandler = ({data, status}) => {
        if (status !== 200)
          throw new Error
        
        let items = data.result.data
        let pagination = data.result.pagination
        this.vehicles = items.map(item => entity.MonitoringListEntity(item))
        this.totalItem = pagination.total_data || 0
      }
      
      Api.vehicle.satellite_list(query)
        .then(responseHandler)
        .catch(e => {
          let err = e.response ? e.response.data : e
          this.$toast.error(err.message || 'Failed to collect vehicle list')
        })
        .finally(() => this.loading = false)
    },

    onFollowVehicle(vehicle) {
      
      this.$router.push({ path: `/vehicle_satellites/${vehicle.vehicle_id}`})

      // this.vehicleMap = entity.VehicleFollowEntity(vehicle)
      // this.clearTracking()
      
    },


    // clearTracking() {
    //   if (!window.animateTracker) return

    //   clearInterval(window.animateTracker)
    //   window.animateTracker = null
    // },

  },
  mounted(){
    this.getMonitoring()
  }
};
</script>
<style lang="scss">
@import 'styles/main.scss';
</style>
