<template lang="pug">
div
  div
    user-privilage-form-modal(
      ref="form"
      @close="showAdd=false"
      :visible="showAdd"
      :currentPrivilage="currentPrivilage"
      @onSave="onDriverChanged"
      @onDelete="onDriverDeleted"
      )
  card(title='User Privilege')
    div.row
      .col-12
        .d-flex.justify-content-end.mb-5
          matador-search#search-box(placeholder='Search User Privilege' autocomplete='off')
          matador-button.ml-3(@click="clickAdd", icon="fa fa-plus")
            | Add User Privilege
        keep-alive
          .table-responsive
            bs-table(
              ref="table"
              :columns="table.columns",
              :options="table.options",
              :data="userList",
              @on-post-body="vueFormatterPostBody"
            )
</template>
<script>
import tableMixin from "@/mixins/table";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import UserPrivilageFormModal from "./UserPrivilageFormModal.vue";
import Dropdown from "@/components/Dropdown.vue";

import {
  getUserPrivilage,
} from "@/utils/api";

export default {
  mixins: [tableMixin],

  components: {
    ChevronDown,
    ChevronUp,
    EyeOutline,
    EyeOffOutline,
    Plus,
    UserPrivilageFormModal
  },

  data() {
    return {
      currentPrivilage: {},
      position: '',
      inputData: {
        username: '',
        fullname: '',
        email: '',
        position: '',
        organization: '',
        password: '',
        password_confirm: '',
      },
      showAdd: false,
      userList: [],
      positionList: [],
      organizationList: [],
      hidepassword: true,
      hideconfirmpassword: true,
      table: {
        options: {
          ajax: this.fetchDriverList,
          search: true,
          searchSelector: "#search-box",
          pagination: true,
          showSearchButton: true,
          sortable: true,
          paginationHAlign: "center",
          paginationDetailHAlign: "right",
          paginationParts: ["pageInfo", "pageList"],
          classes: ["table", "table-hover"],
        },
        columns: [
          {
            title: "Assign Device To",
            field: "user",
            searchable: true,
            sortable: true,
            widthUnit: '%',
            width: '22'
          },
          {
            title: "Email",
            field: "email",
            searchable: true,
            sortable: true,
            widthUnit: '%',
            width: '22'
          },
          {
            title: "Position",
            field: "position",
            searchable: true,
            sortable: true,
            widthUnit: '%',
            width: '22'
          },
          {
            title: "Device Name",
            field: "device_name_final",
            sortable: true,
            searchable: true,
            widthUnit: '%',
            width: '22'
          },
          {
            field: "",
            title: "",
            formatter: (value, row) => {
              return this.vueFormatter({
                template: `<div>
  <dropdown tag="a" class="dropdown-toggle-no-caret" icon="mdi mdi-dots-vertical matador-primary-color h4">
    <li class="p-3 matador-text-03" v-on:click="onEdit(row)">Edit Privilege</li>
    <li class="p-3 matador-text-03" v-on:click="onDelete(row)">Delete Privilege</li>
  </dropdown>
</div>`,
                data: { row, value },
                methods: {
                  onEdit: this.clickEdit,
                  onDelete: this.clickDelete
                },
                components : {
                  Dropdown
                }
              });
            },
            sortable: false,
            searchable: false,
            widthUnit: '%',
            width: '5'
          },
        ],
      },
    };
  },
  methods: {
    clickAdd() {
      this.currentPrivilage = {};
      this.showAdd = true;
    },
    clickEdit(device){
      this.currentPrivilage = device;
      this.showAdd = true;
    },
    clickDelete(device){
      this.currentPrivilage = device;
      this.$refs.form.delete();
    },
    fetchDriverList(params) {
      getUserPrivilage(params.data).then(({ data: { code, result, message } }) => {
        this.userList = result.map(item => {
          if(item.device) {
            item.device_name_final = item.device.map(dev => dev.device_name).join(", ");
            item.device_unit_id = item.device.map(dev => dev.device_unit_id);
          }
          return item;
        });
        params.success(result);
      });
    },
    onDriverChanged(device) {
      this.showAdd = false;
      this.$refs.table.refresh();
      this.$toast.open('Successfully save data.');
    },
    onDriverDeleted() {
      this.$refs.table.refresh();
      this.$toast.open('Successfully delete data.');
    },
  },
  mounted() {
    // reset search if clear
    var that = this;
    window.$('.search input').on('search', function() {
      if(!window.$(this).val()) {
        that.$refs.table.resetSearch();
      }
    });
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.content-filter {
  margin-top: -1rem
}
.tab-filter{
  min-width: 200px;
  border-right: 2px solid #EAEAEC;
  padding-right: 0.5rem;
}
.badge-circle {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    padding: 4px;

    text-align: center;

    font: 10pt Arial, sans-serif;
    font: 10pt Arial, sans-serif;
}
.p-viewer{
  float: right;
  margin-right: 10px;
  margin-top: -33px;
  position: relative;
  z-index: 1;
  cursor:pointer;
}
</style>