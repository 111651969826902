'use strict'

import Dropdown from "@/components/Dropdown.vue";

export const TableOptions = (self) => {

    const actionFormatter = (value, row) => {
        return self.vueFormatter({
            template: `<div>
                <dropdown tag="a" class="dropdown-toggle-no-caret" icon="mdi mdi-dots-vertical matador-primary-color h4">
                <li class="p-3 matador-text-03" v-on:click="onEdit(row)">Edit User</li>
                <li class="p-3 matador-text-03" v-on:click="onDelete(row)">Delete User</li>
                </dropdown>
            </div>`,
            data: { row, value },
            methods: {
              onEdit: self.clickEdit,
              onDelete: self.clickDelete
            },
            components : {
              Dropdown
            }
        });
    }

    const options = {
        ajax: null,
        search: true,
        pagination: true,
        showSearchButton: true,
        sortable: true,
        paginationHAlign: "center",
        paginationDetailHAlign: "right",
        paginationParts: [ "pageInfo", "pageList"],
        classes: ["table", "table-hover"],
        loadingFontSize: 16,
        sidePagination: "server"
    }


    const fields = [
        {
          title: "Username",
          field: "username",
          searchable: true,
          sortable: true,
          widthUnit: '%',
          width: '20'
        },
        {
          title: "Fullname",
          field: "name",
          sortable: true,
          searchable: true,
          widthUnit: '%',
          width: '20'
        },
        {
          field: "email",
          title: "Email",
          sortable: true,
          searchable: true,
          widthUnit: '%',
          width: '20'
        },
        {
          field: "position",
          title: "Position",
          sortable: true,
          searchable: true,
          widthUnit: '%',
          width: '15'
        },
        {
          field: "organization_name",
          title: "Organization",
          sortable: true,
          searchable: true,
          widthUnit: '%',
          width: '15'
        },
        {
          field: "",
          title: "",
          formatter: actionFormatter,
          sortable: false,
          searchable: false,
          widthUnit: 'rem',
          width: '8'
        },
    ]


    return {
        options,
        fields
    }
}

