// import roleGenerator from '@/roles/roleGenerator'

import Page from './Page.vue'

import { TabOption } from './entity'
import MonitoringPage from './pages/Monitor/Monitor.vue'


const Router = () => {

  return {
    path: '/trackerv2',
    name: 'trackerv2',
    // beforeEnter: roleGenerator,
    component: Page,
    children: [
      { path: '', redirect: TabOption.MON.path },
      { path: TabOption.MON.path, component: MonitoringPage }
    ]
  }

}

export default Router