<template lang="pug">
.card.card-monitoring.shadow-sm
  .card-body
    .monitoring-img

      img.mb-2.mb-lg-0(
        width="100%" 
        style="border-radius: 12px; height: 180px" 
        :src="vehicle.thumb || require('@/assets/img/trucks.png')"
      )
      
    //-   dropdown-monitoring(tag="a" class="dropdown-toggle-no-caret" icon="mdi mdi-24px mdi-dots-vertical" style="position:absolute;right:0px !important;top:7px;")
    //-     li(class="pl-3 pr-3 pt-2 pb-2 matador-text-03" style="z-index:2000 !important" ) 
    //-       .d-flex.justify-content-between(@click="$emit('confirm', 'reset', device)")
    //-         .matador-text-02.mr-2 Reset Setting
    //-         div
    //-           button.border-0.bg-transparent(style="position:relative;" title="Reset vehicle data" )
    //-             img.mr-1(width="28" style="padding-top:-20px !mportant" src="@/assets/img/reset-icon-new.png")

    //-     li(class="pl-3 pr-3 pt-2 pb-2 matador-text-03" style="z-index:2000 !important; " ) 
    //-       .d-flex.justify-content-between
    //-         .matador-text-02.mr-2 Relay On/Off
    //-         div
    //-           toggle-button(
    //-             :sync="true"
    //-             :margin="4"
    //-             :width="40"
    //-             :height="22" 
    //-             :value="vehicle.operating" 
    //-             :color="{'checked': '#c0f5c0', 'unchecked': '#ffe0df'}"
    //-             :labels="{'checked': 'On', 'unchecked': 'Off'}"
    //-             @change="$emit('confirm', 'switch', device)"
    //-           )

    p.my-3 {{ vehicle.vehicle_name }}
    .py-1.mb-1
      span.d-block.text-muted.mb-1 VIN
      span {{ vehicle.vin }}

    .py-1.mb-1
      span.d-block.text-muted.mb-1 Device Name
      span {{ vehicle.device_name }}

    .py-1.mb-1
      span.d-block.text-muted.mb-2 Last Data Record
      .matador-h5 {{ vehicle.last_update }}
      
    .pt-3.d-flex.justify-content-stretch
      matador-button.py-0(
        @click="$emit('followVehicle', vehicle)" 
        :outline="false" 
        :block="true" 
        type="primary"
      ) 
        .d-flex.align-items-center.justify-content-center
          div
            img.mr-1(width="20" src="@/assets/img/follow-vehicle-icon.png")
          span(style="line-height: 2rem") Follow My Vehicle

</template>

<script>
import DropdownMonitoring from "@/components/DropdownMonitoring.vue";

const statuses = {
  on: ["On", "text-success"],
  off: ["Off", "text-danger"],
  idle: ["Idle", "text-warning"],
};

export default {
  props: ["vehicle"],

  data() {
    let vehicle = this.vehicle || {};

    return {
      statusLabel: statuses[vehicle.status]
        ? statuses[device.status]
        : statuses["off"],
    };
  },

  components: {
    DropdownMonitoring,
  },
};
</script>
