export const UserListEntity = data => {
    data = data || data

    return {
        user_id: data.user_id,
        username: data.username,
        email: data.email,
        name: data.name,
        position: data.position_name,
        organization_name: data.org_name,
        organization_id: data.organization_id,
        country: data.country
    }
}

export const CountryCodes = {
    'id': {
        code: 'id',
        name: 'Indonesia',
    },
    'mm': {
        code: 'mm',
        name: 'Myanmar'
    }
}

export default {
    UserListEntity,
    CountryCodes
}