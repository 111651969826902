'use strict'

import Dropdown from "@/components/Dropdown.vue";
import { btOptions } from '@/utils/table'

export const TableOption = (self) => {
    const options = btOptions()

    const thumbFormat = (value, row) => {
        const imgSize = size => {
            size = size || 40
            
            return `:height="${size}" :width="${size}"`
        }

        return self.vueFormatter({
            template: `
            <div class="d-flex.align-items-center row" style="margin:10px 10px 0 ">
                <img class="rounded" ${imgSize()} v-if="row.image" :src="row.image"></img>
                <img class="rounded" ${imgSize()} v-if="!row.image" src="car.png"></img>
            </div>`,
            data: { row, value },
        });
    }


    const actionFormat = (value, row) => {
        return self.vueFormatter({
          template: 
          `<div>
            <dropdown tag="a" class="dropdown-toggle-no-caret" icon="mdi mdi-dots-vertical matador-primary-color h4">
              <li class="p-3 matador-text-03" @click="onShow(row)"> Detail </li>
              <li class="p-3 matador-text-03" v-on:click="onEdit(row)">Edit</li>
              <li class="p-3 matador-text-03" v-on:click="onDelete(row)">Delete</li>
            </dropdown>
          </div>`,
          data: { row, value },
          methods: {
            onShow : self.clickDetails,
            onEdit: self.clickEdit,
            onDelete: self.clickDelete
          },
          components : {
            Dropdown
          }
        });
    }

    const columns = [
        {
          title: "",
          field: "",
          searchable: false,
          widthUnit: '%',
          width: '7',
          formatter: thumbFormat,
        },
        {
          title: "Vehicle Name",
          field: "vehicle_name",
          sortable: true,
          searchable: true,
          widthUnit: '%',
          width: '18'
        },
        {
          title: "VIN",
          field: "vin",
          sortable: true,
          searchable: true,
          widthUnit: '%',
          width: '18'
        },
        {
          field: "device_name",
          title: "Device Name",
          sortable: true,
          searchable: true,
          widthUnit: '%',
          width: '24'
        },
        {
          field: "series",
          title: "Series",
          sortable: true,
          searchable: true,
          widthUnit: '%',
          width: '18'
        },
        {
          field: "wiper_width",
          title: "Wiper WIdth",
          sortable: true,
          searchable: true,
          widthUnit: '%',
          width: '15'
        },
        {
          field: "",
          title: "",
          formatter: actionFormat,
          sortable: false,
          searchable: false,
          widthUnit: '%',
          width: '8'
        },
      ]

    return {
        options,
        columns
    }
}