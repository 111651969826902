<template lang="pug">
transition(name="modal")
  div
    .modal-mask(v-if="visible")
      .modal-wrapper
        .modal-dialog(size="sm" role="document")
          .modal-content.rounded-2
            .modal-header
              .h5.font-weight-bold.card-title.px-3  {{ title }} User
              button.close(
                type="button",
                data-dismiss="modal",
                aria-label="Close"
                @click="onClose()"
              )
                span.pr-3(aria-hidden="true") &times;
            
            .modal-body
              loader(:loading="loading")
                form.px-3.mt-3

                  .form-group.error
                    label(for='username') Username
                    input#username.form-control(
                      :disabled="!!user_id" 
                      type='text' 
                      placeholder='Insert username'
                      v-model="username"
                      :class=""
                    )

                  .form-group
                    label(for='fullname') Fullname
                    input#fullname.form-control(type='text' placeholder='Insert your fullname', v-model="full_name")

                  .form-group
                    label(for='email') Email
                    input#email.form-control(type='text' placeholder='Insert your email', v-model="email")

                  .form-group(style="position: relative")
                    label.d-flex.justify-content-between.align-items-center(for='inputPassword') 
                      span Password 
                      span(style="font-size: .7rem") (Min: 6 character)
                    
                    input#inputPassword.form-control(:type='passwordType' @input="missMatch=false" v-model="password" placeholder='Password' required='')
                    a.h3(@click="togglePassword" href="javascript:;" style="position: absolute;bottom: 1px;margin: 0;right: 10px;")
                      .text-muted(v-if="passwordType === 'password'")
                        eye-off
                      .text-primary(v-if="passwordType === 'text'")
                        eye-on


                  .form-group
                    label Country
                    .row
                      .col
                        select.custom-select.form-control(
                          type='text' placeholder='Choose organization' v-model="country"
                        :class="{ 'matador-placeholder': country === ''}" )
                          option(value="" selected disabled) Choose Country
                          option(v-for="item in countryList", :value="item.code") {{item.country_name}}


                  .form-group
                    label Position
                    .row
                      .col
                        select.custom-select.form-control(
                          type='text' 
                          placeholder='Choose your position' 
                          v-model="position"
                          :class="{ 'matador-placeholder': position === '' }"
                        )
                          option(value="" selected disabled) Choose your position
                          option(v-for="item in positions" :value="item.id") {{item.name}}
                      .col-auto.pl-0(v-if="this.auth.role=='superadmin'")
                        matador-button(iconOnly, size="sm", @click="showPositionModal=true")
                          .h3.m-0
                            plus(:size="64")


                  .form-group(v-if="this.auth.role=='superadmin'")
                    label Organization
                    .row
                      .col
                        select.custom-select.form-control(
                          type='text' 
                          placeholder='Choose organization' 
                          v-model="organization_id"
                          :class="{ 'matador-placeholder': organization_id === '' }"
                        )
                          option(value="" selected disabled) Choose organization
                          option(v-for="item in organizations", :value="item.id") {{item.name}}
                      .col-auto.pl-0
                        matador-button(iconOnly, size="sm", @click="showOrganizationModal=true")
                          .h3.m-0
                            plus(:size="64")


                  div.float-right.mt-3
                    button.btn.btn-link.mr-3(type="button" @click="onClose()") Cancel
                    matador-button(:disabled="!isValid" @click="doSave") {{ buttonTitle }}

      confirmation-modal(
        v-if="showCancelModal"
        @close="showCancelModal = false" 
        @confirm="doClose()" 
        title="Discard Changes"
        confirm-text="Yes, Discard"
      )
        p.m-0
          | Are you sure want to discard changes you made?

    organization-modal(
      v-if="showOrganizationModal" 
      @close="showOrganizationModal = false" 
      @appendOrg="appendOrganization"
    )

    position-modal(
      v-if="showPositionModal" 
      @close="showPositionModal = false" 
      @appendRole="appendRole"
    )

    confirmation-modal(
      v-if="showDeleteModal"
      @close="showDeleteModal = false" 
      @confirm="onConfirmDelete" 
      title="Delete User"
      confirm-text="Yes, Delete"
    )
      p.m-0
        | Do yo want to delete this user?

</template>

<script>
import * as yup from 'yup';
import EyeOff from "vue-material-design-icons/EyeOff.vue";
import EyeOn from "vue-material-design-icons/Eye.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import OrganizationModal from "./OrganizationModal.vue"
import PositionModal from "./PositionModal.vue"
import {mapGetters} from "vuex"
import debounce from 'debounce'

import Api from '@/utils/api'
import entity from './entity'


const codes = Object.keys(entity.CountryCodes)
const countries = codes.map(code => {
  let country = entity.CountryCodes[code]
  return country.name
})

const schemas = {
  email: yup.string().email(),
  position: yup.string().required(),
  organization_id: yup.string().required(),
  country: yup.string().required(),
  position: yup.string().required(),
}

let userSchemaAdd = yup.object().shape({...schemas, 
  username: yup.string().required(),
  password: yup.string().required()
});

let userSchemaEdit = yup.object().shape(schemas);

export default {
  props: ['close', 'currentUser', 'onSave', 'onDelete', 'visible'], 
  
  data() {
    return {
      deleteLoading: false,
      country:'',
      missMatch:false,
      passwordType: 'password',
      user_id: null,
      username: '',
      full_name: '',
      email: '',
      position: null,
      organization_id: '',
      password: '',
      confirm_password: '',
      hidepassword: true,
      hideconfirmpassword: true,
      positions: [],
      organizations: [],
      showOrganizationModal: false,
      showPositionModal: false,
      showCancelModal: false,
      showDeleteModal: false,
      loading: false,
      errors: {
        username: '',
        email: '',
        password: ''
      },
      countryList: codes.map(code => {
        let item = entity.CountryCodes[code]
        return {
          code: item.code, 
          country_name: item.name
        }
      })
    }
  },

  watch: {
    currentUser: {
      handler(user) {
        if (user.user_id)
          this.user_id = user.user_id;
        
        this.username = user.username;
        this.full_name = user.name;
        this.email = user.email;
        
        this.position = user.position || '';
        
        this.organization_id = user.organization_id || ''
        this.inputData.organization_id = user.organization_id
        
        this.setCountry(user)
        this.setPosition(user)
      }
    }
  },


  components: {
    Plus,
    EyeOutline,
    EyeOffOutline,
    OrganizationModal,
    PositionModal,
    ConfirmationModal,
    EyeOff,
    EyeOn
  },


  
  computed: {
    ...mapGetters({
      auth:'gettersAuth'
    }),

    isDirty() {
      if(this.currentUser && this.currentUser.user_id) {
        return this.currentUser.username != this.username 
          || this.currentUser.full_name != this.full_name 
          || this.currentUser.email != this.email 
          || this.currentUser.position != this.position 
          || this.currentUser.organization_id != this.organization_id
          || this.currentUser.country != this.country
          
      }
      return this.username || this.full_name || this.email || this.position|| this.organization_id || this.password||this.country;
    },

    isValid() {
      if( this.buttonTitle == "Update" ) return userSchemaEdit.isValidSync(this.inputData);
      return userSchemaAdd.isValidSync(this.inputData);
    },
    
    inputData() {
      let obj = {
        user_id: this.user_id,
        username: this.username,
        // saya komen ini karena didalam api v3 masih blm ada body key full_name,password,confirm_password
        name: this.full_name, 
        email: this.email,
        position: this.position,
        organization_id: this.organization_id,
        password: this.password,
        country: this.country,
        // confirm_password: this.confirm_password
      };
      
      return obj;
    },
    title() {
      return !!this.user_id ? 'Edit' : 'Add'
    },
    buttonTitle() {
      return !!this.user_id ? 'Update' : 'Add'
    }
  },
  methods: {
    // verifyIdentity: () => {
      
    //   const self = this

    //   const verify = () => {
    //     let identity = self.username ? self.username : self.email
    //     if (!identity) return
    //     Api.user.verify_identity(identity)
    //     .then(({data, status}) => {
    //       if (status !== 200)
    //         throw new Error

    //       console.log(data)
    //     })
    //     .catch(e => {

    //     })
    //   }

    //   debounce(verify, 500)
    // },

    setCountry(user) {
        let country = codes[countries.indexOf(user.country)]
        this.inputData.country = country
        this.country = country || '';
    },

    setPosition(user) {
      let positions = this.positions || []
      positions.forEach(item => {
        if (item.name === user.position)
          this.position = item.id
      })
    },

    togglePassword(){
      this.passwordType = this.passwordType == 'text' ? 'password' : 'text';
    },

    transformIdToNameOrganization() {},

    cleaning() {
      this.user_id = null
      this.password = null
    },

    onClose() {
      this.cleaning()

      if(this.isDirty) {
        this.showCancelModal = true;
      } else {
        
        this.doClose();
      }
    },

    doClose() {
      this.showCancelModal = false;
      this.password=null,
      this.passwordType='password'
      this.$emit('close');
    },

    appendOrganization(org) {
      this.organizations = [ {id: org.organization_id, name: org.name}, ...this.organizations ]
      this.organization_id = org.organization_id
    },
    
    appendRole(role) {
      this.positions = [ {id: role.posiiton_id, name: role.name }, ...this.positions ]
      this.position = role
    },


    getPositions() {

      const responseHandler = ({data, status}) => {

        if (status !== 200)
          throw new Error

        this.positions = data.result.data.map(item => ({
            id: item.position_id,
            name: item.name
          }))
      
      }
      
      Api.user.position_list()
      .then(responseHandler)
      .catch(e => {
        let err = e.response ? e.response.data : e
        this.$toast.error(err.message || 'Failed to get position list')
      })
    },

    getOrganizations() {

      // delay needed due to gettter store
      setTimeout(() => {
        if (this.$store.state.auth.role !== "superadmin") return

        Api.user.organization_list()
        .then(({data, status}) => {
          if (status !== 200)
            throw new Error
          
          this.organizations = data.result.data.map(item => ({
            id: item.organization_id,
            name: item.name
          }))
        })
        .catch(e => {
          let err = e.response ? e.response.data : e
          this.$toast.error(err.message || 'Failed to get position list')
        })
      }, 2000)
      
    },
    

    // this method called from UserPage, so do not remove this
    delete() {
      this.showDeleteModal = true;
    },

    doSave() {

      this.loading = true;
      const callback = !!this.user_id ? Api.user.update : Api.user.create
      
      callback(this.inputData)
        .then(({data, status}) => {
          if (status !== 200) 
            throw new Error
          
          this.$emit('onSave', data.message, data.result.data)
          
          this.cleaning()
        })
        .catch(e => {
          let err = e.response ? e.response.data : e
          let message = err.errors
          
          message = !!message.length ? message.join(', ') : err.message
          this.$toast.error(message || 'Failed to save user')
        })
        .finally(() => {
          this.loading = false
        })

    },

    onConfirmDelete() {
      if (!this.user_id)
        return this.$toast.error("Missing user id")

      if (this.deleteLoading) return

      this.deleteLoading = true

      Api.user.remove({user_id: this.user_id})
        .then(({data, status}) => {
          if (status !== 200)
            throw new Error
          this.$emit('onDelete', data.message)
        })
        .catch(e => {
          let err = e.response ? e.response.data : e
          this.$toast.error(err.message || 'Failed to delete user')
        })
        .finally(() => {
          this.showDeleteModal = false
          this.deleteLoading = false
          this.cleaning()
        })
    }
  },
  mounted(){
    this.getPositions()
    this.getOrganizations()
  }
}
</script>