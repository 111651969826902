<template lang="pug">
div
  card(:title="constant.title")
    div.row
      .col-12
        .d-flex.justify-content-end.mb-5
          matador-search#search-box(placeholder='Search' v-model="search" autocomplete='off')
          matador-button.ml-3(@click="clickAdd", icon="fa fa-plus")
            | {{ constant.add_button }}

        keep-alive
          .table-responsive(v-if="auth && auth.organization_id")
            bs-table(
              ref="table"
              :columns="table.columns",
              :options="table.options",
              :data="userList",
              @on-post-body="vueFormatterPostBody"
              @on-search="onSearchChange"
            )

  vehicle-modal(
    v-if="showVehicleDetail"
    :vehicle="vehicle" 
    @close="showVehicleDetail = false"
    @success=""
  )

  vehicle-form-modal(
    v-if="showVehicleAdd" 
    :vehicle="vehicle" 
    @close="showVehicleAdd = false" 
    @success="onVehicleChanged"
  )

  vehicle-form-modal(
    v-if="showVehicleEdit" 
    :vehicle="vehicle" 
    @close="showVehicleEdit = false"
    @success="onVehicleChanged"
  )

  confirmation-modal(
    v-if="deleteModal"

    :vehicle="vehicle"
    :title="constant.delete_title"
    :confirm-text="constant.delete_confirm"
    @close="deleteModal = false" 
    @confirm="onConfirmDelete" 
  )        
    p.m-0 {{ constant.delete_question }}
     

</template>
<script>
import tableMixin from "@/mixins/table";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";

// import VehicleFormModal from "@/components/Matador/VehicleFormModal";
// import VehicleModal from "@/components/Matador/VehicleModal";

import VehicleFormModal from '../components/VehicleFormModal.vue'
import VehicleModal from '../components/VehicleModal.vue'
import Api from '@/utils/api'
import Config from '@/config'

import dayjs from "dayjs";
import {TableList} from './table'
import {queryTableNormalize} from '@/utils/table'


export default {
  mixins: [tableMixin],

  components: {
    ChevronDown,
    ChevronUp,
    EyeOutline,
    EyeOffOutline,
    ConfirmationModal,
    PlusIcon,
    VehicleFormModal,
    VehicleModal,
    
  },

  data() {
    return {
      constant: {
        title: 'Satellite Vehicle',
        add_button: 'Add Vehicles',
        delete_question: 'Are you sure want to delete this vehicle ?',
        delete_title: 'Delete Vehicle',
        delete_confirm: 'Yes, Delete'
      },

      search:'',
      showVehicleAdd : false,
      showVehicleEdit:false,
      deleteModal:false,
      showVehicleDetail: false,
      vehicle:'',
      organizationList:[],
      position: '',
      currentUser: this.$store.state.auth.user_id,
      showAdd: false,
      userList: [],
      perPage: 10,
      table: {
        options: {
            ...TableList(this).options,
            ajax: this.getVehicle,
            pageSize: this.perPage,
            searchSelector: '#search-box',
        },
        columns: TableList(this).columns,
      },
    };
  },
  computed: {
    auth(){
      return this.$store.state.auth;
    },
  },
  methods: {

    async getVehicle(params) {

        const responseHandler = ({data, status}) => {
            if (status !== 200)
              throw new Error
            
            let rows = data.result.data
            let pagination = data.result.pagination
            if (!rows || !rows.length)
              return params.success(false)
            
            let resource = {
                rows,
                total: pagination.total_data,
                totalNotFiltered: pagination.total_data
            }

            setTimeout(() => params.success(resource), 500)
        }

        let payload = queryTableNormalize(params)
        payload.device_type = Config.app.satelite_device_type_id
        return Api.vehicle.list(payload)
            .then(responseHandler)
            .catch(e => {
                console.log(e)
                params.error(true)
            })
    },


    onSearchChange() {},


    clickDetails(row){
      this.showVehicleDetail = false

      let payload = {
        unit_id: row.unit_id  ,
        trip: dayjs().format("YYYY-MM-DD"),
      }

      Api.vehicle.detail_vehicle(payload)
        .then(({ data: { code, result, message } }) => {
          this.vehicle = result;
        });
        this.showVehicleDetail = true
    },


    clickAdd() {
      this.showVehicleAdd = false;
      this.vehicle = null;
      this.showVehicleAdd = true;      
    },


    clickEdit(row){
      this.showVehicleEdit = false
      this.vehicle= row
      this.showVehicleEdit = true
    },

    clickDelete(row){
       this.deleteModal = false;
       this.vehicle= row
       this.deleteModal = true;
    },


    onConfirmDelete(){
      this.deleteModal = false;

      Api.vehicle.delete({unit_id: this.vehicle.unit_id})
        .then(({data, status}) => {
            let code = data.code

            if (code !== 200)
                throw new Error(data.message)

            this.$toast.success('The vehicle successfully deleted.')
            this.$refs.table.refresh();
        })
        .catch(e => {
            let err = e.response ? e.response.data : e
            this.$toast.success(err.message)
        })
    },
    
    onVehicleChanged(vehicle){
      this.showVehicleAdd = false;
      this.showVehicleEdit = false;
      this.$refs.table.refresh();
      this.search = vehicle.vin;
    },
    
  },
  watch: {
    pageSize() {
      this.$refs.table.refreshOptions();
    },
  }
  
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.content-filter {
  margin-top: -1rem
}
.tab-filter{
  min-width: 200px;
  border-right: 2px solid #EAEAEC;
  padding-right: 0.5rem;
}
.badge-circle {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    padding: 4px;

    text-align: center;

    font: 10pt Arial, sans-serif;
    font: 10pt Arial, sans-serif;
}
.p-viewer{
  float: right;
  margin-right: 10px;
  margin-top: -33px;
  position: relative;
  z-index: 1;
  cursor:pointer;
}
</style>
