<template lang="pug">
div
  .nav-dropdown.menu-item(@click="openDropdown")
    i.fa.fa-wifi
    p Satellite
    svg(viewBox="0 0 1030 638" width="10" :style="arrowStyle")
      path(d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" fill="#8B90A0")

  transition(name="fade" appear)
    .sub-item(v-if="isOpen")
      div(v-for="item in menus" :key="item.id")
        router-link.subnav-dropdown(:to="item.to")
          p {{ item.name }}
</template>

<script>
export default {
  name: "satellite-sub-menu",
  
  data() {
    return {
      isOpen: false,
      arrow: "rotate(0deg)",
      menus: [
        {id: 'manage-satellite', to: '/vehicle_satellites', name: "Manage Vehicle Satellite"},
        {id: 'follow-vehicle-satellite', to: '/vehicle_satellites/follows', name: "Follow Vehicle"},
      ],
    }
  },
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: screen.width < 760
    },
    addLink: {
      default: ()=>{}
    },
    removeLink: {
      default: ()=>{}
    }
  },
  props: {
    name: String,
    icon: String,
    tag: {
      type: String,
      default: "router-link"
    }
  },


  computed:{
    arrowStyle(){
      return {
        transform: this.arrow
      }
    }
  },
  

  methods: {
    hideSidebar() {
      if (this.autoClose && screen.width < 760) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains("active");
    },


    openDropdown(){



      if(this.isOpen){
        this.isOpen= !this.isOpen;
        this.arrow="rotate(0deg)";
        // console.log(this.arrow)
      }else{
        this.isOpen= !this.isOpen;
        this.arrow="rotate(180deg)";
        // console.log(this.arrow)
      }
    }

  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
      this.isOpen=false
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  }
};
</script>
<style>

</style>
