<template lang="pug">

div.modal-mask(v-if="isAddGeneratorOpen")
  .modal-wrapper
    .modal-dialog.modal-md.modal-dialog-scrollable(role="document")
      .modal-content

        .modal-header.d-flex.justify-content-between.align-items-center
          .h5.font-weight-bold.card-title.px-2 Add Device
          button.close(
            type="button",
            data-dismiss="modal",
            aria-label="Close",
            @click="$emit('close')"
          )
            span.pr-3(aria-hidden="true") &times;
            
        div.modal-body
          .row
            .col-12.px-4
            
              b-form-group(label="Device Name")
                b-form-input(v-model="name" placeholder="New generator name")

              b-form-group(label="Device Name")
                multiselect(
                  v-model="selectedDevice",
                  track-by="id",
                  label="name",
                  placeholder="Device name",
                  :multiple="false",
                  :options="devices",
                  :searchable="true",
                  :allow-empty="false",
                  :closeOnSelect="true"
                )
            
              b-form-group(label="Description")
                b-form-textarea(v-model="description" :rows="4" placeholder="Simple description")

        div.modal-footer
          .col-12.d-flex.justify-content-between
            button.btn.btn-light.border-0(@click="$emit('close')")
              | Cancel
            button.btn.btn-danger(:disabled="isStorable" @click="addGenerator") 
              | {{ loading ? 'Loading..' : 'Save' }} 

</template>
<script>
import { Generator } from "../../libs/repository";
import { GetError } from "../../utils";

const Repository = Generator.init();

export default {
  props: ["isAddGeneratorOpen", "table", "deviceNames"],

  data() {
    return {
      name: "",
      description: "",
      location: "",
      selectedDevice: null,
      loading: false,
    };
  },

  watch: {
    deviceNames: {
      handler: function (devices) {
        this.devices = devices;
      },
    },
  },

  methods: {
    addGenerator() {
      if (this.loading) return false;
      this.loading = true;

      let payload = {
        name: this.name,
        description: this.description,
        devices: [],
        location: this.location,
        device_id: this.selectedDevice.id,
      };

      const cleaning = () => {
        this.name = "";
        this.description = "";
        this.selectedDevice = null;
      };

      const responseFactory = (result) => {
        let message = result.message || "Success";

        this.$toast.success(message);
        this.$emit("close");
        this.table.refresh();
        this.$emit("refreshDevice", this.selectedDevice);

        cleaning();
      };

      Repository.createGenerator(payload)
        .then(responseFactory)
        .catch((e) => {
          let message = GetError(e, "Failed to dispatch device status");
          this.$toast.error(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  computed: {
    isStorable() {
      return this.name &&
        this.name.length &&
        this.selectedDevice &&
        this.selectedDevice.id
        ? false
        : true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/add_generator.scss";
</style>
