<template lang="pug">
div#vehicle-satelitte-report

  .card.p-3
    .card-header
      div.d-flex.justify-content-between.align-items-center
        h5.font-weight-bold.card-title {{ title }} 
        button.btn.btn-light(@click="$router.go(-1)") {{ t('Back') }}

    .card-body
      div.row
        div.col-lg-12
          .card.vehicle-info
            .card-body.p-3.meta-info
              
              div.d-flex
                div(style="width: 70%")
                  loader(:loading="detailLoading")
                    div
                      div.d-flex
                        div(style="max-width:140px;")
                          div.picture-placeholder-wrapper
                            img.picture-placeholder(:src="vehicle.thumb || require('@/assets/img/car.png')")

                        div.m-0.p-0
                          table.table.table-borderless.m-0.p-0
                            tr(v-for="[attr, label, value] in infos.left")
                              td {{ label }}
                              td :
                              td <strong v-html="vehicle[attr]"></strong>
                    div.mt-3
                      div.d-flex
                        div.mr-3 
                          p.d-flex.justify-content-between(style="width: 150px; font-size: 0.7rem!important") 
                            | {{ t('Total Opt Time') }}  <strong>: {{vehicle.total_opt_time}}</strong>
                        div 
                          p.d-flex.justify-content-between(style="width: 150px; font-size: 0.7rem!important") 
                            | {{ t('Total Opt Mil') }}  <strong>: {{vehicle.total_opt_mil}}</strong>

                div(style="width: 100%")
                  loader.loader.d-flex(:loading="statisticLoading")
                    div.m-0.p-0
                      table.table.table-borderless.m-0.p-0
                        tr(v-for="[attr, label, value, unit] in infos.right")
                          td {{ label }}
                          td :
                          td <strong>{{ stats[attr] }} {{ unit }}</strong>

                    div.m-0.p-0
                      div.d-flex.justify-content-center.align-items-center.mb-2
                        span.btn.btn-opt-chart.time(@click="onChangeChart(chartName.TIME)") {{ t('Operating Time') }}
                        span.btn.btn-opt-chart.mileage(@click="onChangeChart(chartName.MIL)") {{ t('Operating Mil') }}
                      div
                        bar-chart(
                          v-if="chartActive === chartName.MIL"
                          :chart-data="chart(chartName.MIL, 'data')" 
                          :options="chart(chartName.MIL, 'options')" 
                          :stacked="false"
                          :height="150" 
                          :width="null"
                        )
                        line-chart(
                          v-if="chartActive === chartName.TIME"
                          :chart-data="chart(chartName.TIME, 'data')" 
                          :options="chart(chartName.TIME, 'options')" 
                          :stacked="false"
                          :height="150" 
                          :width="null"
                        )
              
                    

          .card.vehicle-info
            .card-header
              .row
                .col-12.col-md-4.px-2
                  div.d-flex.justify-content-stretch.align-items-center
                    label.mr-2 {{ t('Start Date') }}
                    datepicker(
                      style="flex: 1"
                      input-class="form-control datepicker" 
                      @selected="onSelectStartDate" 
                      name="startDate" 
                      ref="startDate"
                      :placeholder="t('Select start date')"
                    )

                .col-12.col-md-4.px-2
                  div.d-flex.justify-content-stretch.align-items-center
                    label.mr-2 {{ t('End Date') }}
                    datepicker(
                      style="flex: 1"
                      input-class="form-control datepicker" 
                      @selected="onSelectEndDate" 
                      name="endDate" 
                      ref="endDate"
                      :placeholder="t('Select end date')"
                    )
                .col-12.col-md-2.px-2
                  matador-button.py-1.px-4(
                    size="lg" 
                    style="width: 100%; border: 3px solid #1f548b;"
                    :disabled="!isClickable"
                    @click="onGenerate"
                  ) {{ t('Generate') }}

                .col-12.col-md-2.px-2
                  matador-button.py-1.px-4(
                    size="lg" 
                    style="width: 100%; border: 3px solid #0CCA4A!important; background: #0CCA4A!important;"
                    @click="onPrint"
                    :disabled="!isClickable || loadingPrint"
                  ) {{ loadingPrint ? t('Loading') : t('Print') }}
                
            .card-body.mt-3.p-0
              keep-alive
                .table-responsive(style="minHeight: 300px")
                  bs-table#history-table(
                    style="border: none"
                    ref="table"
                    :columns="table.fields",
                    :options="table.options",
                    :data="histories",
                    @on-post-body="vueFormatterPostBody"
                  )
          
</template>
<script>
import BarChart from "@/components/Chartjs/BarChart";
import LineChart from "@/components/Chartjs/LineChart";
import Datepicker from "vuejs-datepicker";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import tableMixin from "@/mixins/table";
import { queryTableNormalize } from "@/utils/table";

import { TableReport } from "./table";
import entity from "../entity";
import dayjs from "dayjs";
import Api from "@/utils/api";
import { TimeFormat } from "@/utils/utilities";

const ChartName = {
  TIME: "operate_time",
  MIL: "operate_mileage",
};

const chartTitleLabel = dayjs().format("MM/YYYY");

export default {
  mixins: [tableMixin],

  components: {
    Datepicker,
    ConfirmationModal,
    LineChart,
    BarChart,
  },

  data() {
    return {
      prefixTitle: "Satellite Detail",

      infos: {
        left: [
          ["vehicle_name", "Vehicle Name", ""],
          ["vin", "VIN", ""],
          ["device_name", "Device Name", ""],
          ["last_update", "Last Data Record", ""],
          ["last_position", "Last Position", ""],
        ],
        right: [
          ["period", "Period", "1 Month", ""],
          ["max_opt_time", "Max Opt Time", "", "H"],
          ["min_opt_time", "Min Opt Time", "", "H"],
          ["max_opt_mil", "Max Opt Mil", "", "Km"],
          ["min_opt_mil", "Min Opt Mil", "", "Km"],
          ["daily_avg_opt_time", "Daily AVG Opt Time", "", "H"],
          ["daily_avg_opt_mil", "Daily AVG Opt Mil", "", "Km"],
        ],
      },

      vehicle_id: null,
      startDate: null,
      endDate: null,

      detailLoading: false,
      statisticLoading: false,
      loadingPrint: false,
      title: "",

      totalOptTime: 0,
      totalOptMil: 0,

      histories: [],
      chartActive: ChartName.TIME,
      chartName: ChartName,
      chartLogs: {
        labels: [],
        operate_time: {
          yTitle: "Hour",
          datasets: [
            {
              label: "Operating Time",
              data: [],
            },
          ],
        },
        operate_mileage: {
          yTitle: "Km",
          datasets: [
            {
              label: "Operating Mileage",
              data: [],
            },
          ],
        },
      },

      vehicle: entity.MonitoringListEntity(),
      stats: entity.DetailStatEntity(),
      table: {
        options: {
          ...TableReport(self).options,
          ajax: this.colllectLogHistories,
          paginationParts: ["pageInfo", "pageList", "pageNumbers"],
        },
        fields: TableReport(self).columns,
      },
    };
  },

  methods: {
    t(str) {
      return str;
    },

    async getVehicleDetail() {
      if (this.detailLoading) return false;
      this.detailLoading = true;

      const responseHandler = ({ data, status }) => {
        if (status !== 200) throw new Error();

        this.vehicle = entity.MonitoringListEntity(data.result.data);
        this.title = `${this.prefixTitle} - ${this.vehicle.vehicle_name}`;

        this.vehicle.last_position = `${this.vehicle.latitude},<br />${this.vehicle.longitude}`;
      };

      return Api.vehicle
        .satellite_detail({ vehicle_id: this.vehicle_id })
        .then(responseHandler)
        .finally(() => (this.detailLoading = false));
    },

    onSelectStartDate(date) {
      this.startDate = dayjs(date).format("YYYY-MM-DD");
    },
    onSelectEndDate(date) {
      this.endDate = dayjs(date).format("YYYY-MM-DD");
    },

    onPrint() {
      if (this.loadingPrint) return;
      this.loadingPrint = true;

      let payload = {
        limit: -1,
        export_type: "excel",
        start_date: this.startDate,
        end_date: this.endDate,
      };

      Api.vehicle
        .satellite_download_report(this.vehicle_id, payload)
        .then(({ data, status }) => {
          if (status === 200 && data && data.result.data.file) {
            window.open(data.result.data.file, "_blank");
            // DownloadFile(data.result.data.file)
          }
        })
        .catch((e) => console.log(e))
        .finally(() => (this.loadingPrint = false));
    },

    onGenerate() {
      this.$refs.table.refresh();
    },

    onChangeChart(name) {
      this.chartActive = name;
    },

    chart(key, prop) {
      let selected = this.chartLogs[key];
      if (!selected) return {};

      let result = {
        data: {
          labels: this.chartLogs.labels,
          datasets: selected.datasets,
        },

        options: {
          legend: { display: false },

          elements: {
            point: { radius: 1 },
          },

          tooltips: {
            enabled: true,
            mode: "single",
            callbacks: {
              title: (item, data) => {
                return `Date: ${item[0].xLabel}/${chartTitleLabel}`;
              },
              label: (item, data) => {
                if (key === "operate_time") {
                  let time = `${item.yLabel}`.split(".");
                  let hour = time[0];
                  let min = time[1] || "00";
                  return `${hour}h ${min}m`;
                }

                return `${item.yLabel} km`;
              },
            },
          },
        },
      };

      if (prop === "data") return result.data;

      return result.options;
    },

    collectStatistic() {
      if (this.statisticLoading) return;
      this.statisticLoading = true;

      let currentMonth = dayjs().format("YYYY-MM");
      let instance = dayjs(`${currentMonth}-01`);

      let payload = {
        limit: -1,
        start_date: instance.format("YYYY-MM-DD"),
        end_date: instance
          .add(1, "month")
          .subtract(1, "day")
          .format("YYYY-MM-DD"),
      };

      const reformatLogs = (logs) => {
        let month = dayjs().format("YYYY-MM");
        let days = dayjs(`${month}-01`)
          .add(1, "month")
          .subtract(1, "day")
          .format("DD");
        days = Number(days);

        let labels = [];
        let optTime = [];
        let optMil = [];

        let totalOptTime = 0;
        let totalOptMil = 0;

        let items = {};
        logs.forEach((item) => {
          if (item.label) {
            items[Number(dayjs(item.label).format("DD"))] = item;
          }
        });
        [...Array(days).keys()].forEach((i) => {
          let day = i + 1;
          labels.push(day);

          let item = items[day];
          if (item) {
            let time = Number(item.opt_time);
            totalOptTime += time;

            let format = TimeFormat.secToHour(time);
            time = Number(`${format.h}.${format.m}`);
            optTime.push(time);

            let mil = Number(item.opt_mil);
            mil = Number((mil / 1000).toFixed(2));
            optMil.push(mil);
          } else {
            optTime.push(0);
            optMil.push(0);
          }
        });

        labels = labels.reverse();
        optTime = optTime.reverse();
        optMil = optMil.reverse();

        return {
          labels: labels.reverse(),
          optTime: optTime.reverse(),
          optMil: optMil.reverse(),
          totalThisMonth: {
            optTime: totalOptTime,
            optMil: totalOptMil,
          },
        };
      };

      const reponseHandler = ({ data, status }) => {
        if (status === 200) {
          this.stats = entity.DetailStatEntity(data.result.data.stats);
          let { labels, optTime, optMil } = reformatLogs(data.result.data.logs);

          this.chartLogs.labels = labels;
          this.chartLogs[ChartName.TIME].datasets = [
            {
              borderColor: "#7998B9",
              data: optTime,
              backgroundColor(ctx) {
                const canvas = ctx.chart.ctx;
                const gradient = canvas.createLinearGradient(0, 0, 0, 160);

                gradient.addColorStop(0, "#D0D7E7");
                gradient.addColorStop(1, "#F1F6FF");
                return gradient;
              },
              tension: 0.25,
            },
          ];

          this.chartLogs[ChartName.MIL].datasets = [
            {
              backgroundColor: "#FDCA40",
              data: optMil,
            },
          ];
        }
      };

      Api.vehicle
        .satellite_get_statistic(this.vehicle_id, payload)
        .then(reponseHandler)
        .finally(() => (this.statisticLoading = false));
    },

    colllectLogHistories(params) {
      if (!this.startDate || !this.endDate) return params.success(true);

      const reponseHandler = ({ data, status }) => {
        if (status === 200) {
          let rows = data.result.data;
          let pagination = data.result.pagination;

          this.histories = rows.map((item) => {
            item = entity.LogSatelliteEntity(item);
            item.timestamp = dayjs(item.timestamp)
              .add(1, "hour")
              .format("DD/MM/YYYY HH:mm A");
            return item;
          });

          if (!rows || !rows.length) return params.success(false);
          let resource = {
            rows: this.histories,
            total: pagination.total_data,
            totalNotFiltered: pagination.total_data,
          };
          setTimeout(() => params.success(resource), 1000);
        }
      };

      let payload = queryTableNormalize(params);
      payload.start_date = this.startDate;
      payload.end_date = this.endDate;

      Api.vehicle
        .satellite_get_history_by_date(this.vehicle_id, payload)
        .then(reponseHandler)
        .catch((e) => {
          params.success(false);
        });
    },
  },

  computed: {
    lastUpdate() {
      if (!this.vehicle.last_update) return "-";
      return dayjs(this.vehicle.last_update)
        .add(1, "hour")
        .format("DD/MM/YYYY HH:mm A");
    },

    isClickable() {
      return this.startDate && this.endDate;
    },
  },

  mounted() {
    this.vehicle_id = this.$route.params.vehicle_id;
    this.getVehicleDetail();
    this.collectStatistic();
  },
};
</script>

<style lang="scss">
#vehicle-satelitte-report {
  .btn-opt-chart {
    padding: 0 20px;
    margin: 0 2px;
    border: none;

    &.time {
      background: #1f548b;
    }
    &.mileage {
      background: #fdca40;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .pagination-info {
    display: none;
  }

  .loader {
    justify-content: center;

    & > div {
      display: flex;
    }
  }

  .bootstrap-table {
    font-size: 0.8rem !important;

    .fixed-table-container {
      border: none;
    }

    .table {
      td {
        font-size: 0.8rem !important;
        padding: 7px 10px !important;
      }
    }
  }

  .table {
    border: none;
    font-size: 0.7rem !important;

    th {
      color: #000;
      font-weight: bolder;
      font-size: 0.7rem !important;
    }

    td {
      color: #717171;
      padding: 3px 10px !important;
    }
  }

  .picture-placeholder-wrapper {
    background: #efefef;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .picture-placeholder {
    width: 100%;
    height: 100%;
  }
}
</style>
