<template lang="pug">
transition(name="modal")
  div
    .modal-mask
      .modal-wrapper
        .modal-dialog(style="width: 470px;max-width: 90%;" role="document")
          .modal-content.rounded-2
            .modal-body.p-0
              loader(:loading="saving")
                .d-flex.justify-content-between.border-bottom.py-3.px-4.mt-1
                  h4.h5.m-0.text-dark.font-weight-bold Add Series Type
                  a.bg-white.border-none(
                    href="javascript:;"
                    role="button",
                    data-dismiss="modal",
                    aria-label="Close"
                    @click="$emit('close')"
                  )
                    div
                      img(:height="14" :width="14" src="@/assets/img/close.png")
                .py-4.my-2.px-4
                  b-form-group(label="Vehicle Type")
                        b-form-select(:options="optionTypes" v-model="vehicle_type")
                  b-form-group(label="Brand")
                        b-form-select(:options="optionBrands" v-model="vehicle_brand" placeholder="Insert Brand")
                  b-form-group(label="Series")
                        b-form-input(v-model="vehicle_series" placeholder="Insert Series")
                  b-form-group(label="Engine")
                        b-form-input(v-model="vehicle_engine" placeholder="Insert Engines")          
                  .row
                    .col.py-2
                        b-form-group(label="Tire Count")
                        b-form-input(type="number" min="0" v-model="vehicle_tire" placeholder="Insert Tire Count" required)
                    .col.py-2
                        b-form-group(label="Torque")
                        b-form-input(type="number" min="0" v-model="vehicle_torque" placeholder="Insert Torque")

                  .row
                    .col.py-2
                        b-form-group(label="Year")
                        b-form-input(type="number" min="1980" v-model="vehicle_year" placeholder="Insert Year")
                    .col.py-2
                        b-form-group(label="Fuel")
                        b-form-input(type="number" min="0" v-model="vehicle_fuel" placeholder="Insert Fuel")


                .py-3.px-4.d-flex.justify-content-end.align-items-center
                  button.border-0.bg-white.py-1.px-5.text-danger(style="text-transform: none;" @click="$emit('close')") Cancel
                  button.px-4.btn-sm.btn.btn-primary(style="text-transform: none;" @click="onSave" :disabled="optionTypes===null || optionBrands === null") Add
</template>

<script>
import { getVehicleType, postAddSeries, getBrandVehicleList } from "@/utils/api";
export default {
  data(){
    return {
      vehicle_type: null,
      vehicle_brand: null,
      vehicle_series: null,
      vehicle_engine: null,
      vehicle_tire: null,
      vehicle_year:null,
      vehicle_torque: null,
      vehicle_number: null,
      vehicle_fuel: null,
      optionTypes: [],
      optionBrands: [],
      saving: false,
    }
  },
  watch: {
    
  },
  components: {
    // Datepicker
  },
  props: ['close', 'success'],  
  computed: {
    
  },
  methods: {
    getTypes(payload){
      getVehicleType().then(({ data: { result } }) => {
        this.optionTypes = result.map(item => {
          item.value = item.vehicle_type_id;
          item.text = item.name.split(' ').map(str => {
            return str[0].toUpperCase() + str.substring(1)
          }).join(' ')
          return item;
        });
        if(!!payload){
          this.vehicle_type = payload;
        }
      });
    },
    getBrands(){
      getBrandVehicleList().then(({ data: { result } }) => {
        this.optionBrands = result.map(item => {
          item.value = item.brand_id;
          item.text = item.name.split(' ').map(str => {
            return str[0].toUpperCase() + str.substring(1)
          }).join(' ')
          return item;
        });
      });
    },
    onSave(){
      postAddSeries({
        vehicle_type_id: this.vehicle_type,
        series: this.vehicle_series,
        engine: this.vehicle_engine,
        tire_count: this.vehicle_tire,
        torque: this.vehicle_torque,
        year: this.vehicle_year,
        fuel: this.vehicle_fuel,
        brand_id: this.vehicle_brand
      }).then(({ data: { result} }) => {
        this.$emit('success', result);
        this.$emit('close');
      })
    }
  },
  mounted(){
        this.getTypes(localStorage.vehicle_type_id);
        this.getBrands();
  }
}
</script>