<template lang="pug">
div
  div
    track-form-modal(
      ref="form"
      @close="showAdd=false"
      :visible="showAdd"
      :currentTrack="currentTrack"
      @closeModal="showAdd = false"
      @onSave="onTrackChanged"
      @onDelete="onTrackDeleted"
    )
    track-loading-modal(v-if="$store.state.showLoadingTrack")
  .row
    .col-12
      card.card
        .row
          .d-flex.flex-column.col-md-12.col-xl-6.mb-4
            span.matador-h3 Show Tracks
              matador-button.float-right(@click="clickAdd", icon="fa fa-plus")
                | Add Tracks
            .mt-4
              matador-search#search-button(placeholder='Search' autocomplete='off')
            .mt-4
              .table-responsive
                  bs-table#trackingTable(
                  ref="table"
                  :columns="table.columns",
                  :options="table.options",
                  :data="trackList",
                  @on-check="toggleCheck"
                  @on-check-all="onCheckAll"
                  @on-uncheck="toggleCheck"
                  @on-uncheck-all="onUnCheckAll"
                  @on-page-change="onPageChange"
                  @on-post-body="vueFormatterPostBody")
          .d-flex.flex-column.col-md-12.col-xl-6
            .d-flex.flex-fill.flex-column
              .d-flex.flex-fill.flex-column(style="position: relative")
                .mapLoading(v-if="loadingMap")
                  p Loading Map
                #mapTrack(style="height: 80vh")
</template>
<script>
import { Card, StatsCard, ChartCard, PaperTable } from "@/components/index";
import Datepicker from "vuejs-datepicker";
import tableMixin from "@/mixins/table";

import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft.vue";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight.vue";
import TrackFormModal from "./TrackFormModal.vue";
import TrackLoadingModal from "./TrackLoadingModal.vue";

import Config from '@/config'
import Api from '@/utils/api'
import { tableOptions } from './table'
import renderTrack from './render_track'

export default {
  mixins: [tableMixin],


  components: {
    TrackLoadingModal,
    StatsCard,
    ChartCard,
    Card,
    PaperTable,
    ChevronLeftIcon,
    ChevronRightIcon,
    Datepicker,
    TrackFormModal
  },
  


  data() {
    return {
      zoom:13,
      isInit: false,
      showAdd: false,
      currentTrack: {},
      trackList: [],
      trackMapList: [],
      map: null,
      bounds: null,
      marker: [],
      table: tableOptions(this),
      showModal: false,

      loadingMap: false,
    };
  },
  
  
  
  methods: {
    onClickRow(row) {
      if (this.loadingMap) return
      this.loadingMap = true

      const responseHandler = ({data, status}) => {
        if (status !== 200)
            throw new Error("Failed to get data")

          row.location = data.result.data
          let {marker, bounds, streetPath} = renderTrack(row, this.map)
      
          if (streetPath) streetPath.setMap(this.map)
          if (marker) marker.setMap(this.map)

          this.map.fitBounds(bounds)
      }

      Api.tracking.location(row)
        .then(responseHandler)
        .catch(e => {
          let error = e.response ? e.response.data : e
          this.$toast.error(error.message || 'Failed to load map')
        })
        .finally(() => {
          this.loadingMap = false
        })
    },

    onCheckAll( after, before ) {
      var already = before.map((item) => item.tracking_unit_id);
      var changed = after.filter((item) => !already.includes(item.tracking_unit_id));
      
      let status
      let ids = changed.map(item => {
        status = item.is_view
        return item.tracking_unit_id
      })
      
      if (!!ids.length) this.postCheckedChange(ids, status)
    },

    onUnCheckAll( after, before) {

      var already = after.map((item) => item.tracking_unit_id);
      var changed = before.filter((item) => !already.includes(item.tracking_unit_id));
      
      let status
      let ids = changed.map(item => {
        status = item.is_view
        return item.tracking_unit_id
      })
      
      if (!!ids.length) this.postCheckedChange(ids, status)
    },


    toggleCheck(row, el) {
      let status
      this.trackList = this.trackList.map(item => {
        if (item.tracking_unit_id === row.tracking_unit_id) {
          item.is_view = !item.is_view
          status = item.is_view ? 1 : 0
        }
        return item
      })
      this.postCheckedChange([row.tracking_unit_id], status)
    },

    postCheckedChange(ids, is_view) {

      const responseHandler = ({data, status}) => {
        if (status === 200)
          this.$refs.table.refresh();
      }

      Api.tracking.toggle_check({ids, status: is_view})
      .then(responseHandler)
      .catch(e => {
        let error = e.response ? e.response.data : e
        this.$toast.error(error.message || 'Failed to update')
      })
      
    },


    onPageChange(){
      this.$refs.table.refresh();
    },
    

    onTrackChanged(track) {
      this.showAdd = false
      this.$refs.table.refresh();
      this.$toast.open('Successfully added new track');
    },


    onTrackDeleted() {
      this.currentTrack = {}
      this.$refs.table.refresh();
      this.$toast.open('Successfully delete track data.');
    },


    clickAdd() {
      this.currentTrack = {};
      this.showAdd = true;
    },

    clickEdit(device){
      this.currentTrack = device;
      this.showAdd = true;
    },

    clickDelete(device){
      this.currentTrack = device;
      this.$refs.form.delete();
    },




    fetchTrackTableList(params) {
      
      const responseHandler = ({data}) => {
        let result = data.result.data
        this.trackList = result;
        params.success(result);
      }

      let payload = {
        limit: params.data.limit || 10,
        page: params.data.offset/params.data.limit || 0,
        q: params.data.search,
        sort: `${params.data.sort || 'tracking_unit_id'}.${params.data.order || 'asc'}`,
      }

      Api.tracking.list(payload)
        .then(responseHandler)
        .catch(e => {
          this.$toast.error("Failed to get list vehicle")
        })
      
    },



    initGmap() {
        var initLatLng = new window.google.maps.LatLng(Config.google.map.lat, Config.google.map.lng);

        var mapOptions = {
          zoom: this.zoom,
          scrollwheel: true, 
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          center: initLatLng,
          mapTypeControlOptions: {
              position: window.google.maps.ControlPosition.TOP_RIGHT
          }
        };

        this.map = new window.google.maps.Map(
            document.getElementById("mapTrack"),
            mapOptions
        );

        this.bounds = new window.google.maps.LatLngBounds();
    }
  },


  mounted() {
    var that = this;

    initCallback(1000, 10, (done) => {
      that.initGmap();
      // that.fetchTrackMapList();
      done()
    })
  },
};
</script>
<style>
#trackingTable tr {
  cursor: pointer;
}
.mapLoading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgb(238, 238, 238, 1);
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
}
.mapLoading p { font-weight: bold; width: 100%; text-align: center; }

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
