<template lang="pug">
div
  user-form-modal(
    ref="form"
    @close="showAdd=false"
    :visible="showAdd"
    :currentUser="currentUser"
    @onSave="onUserChanged"
    @onDelete="onUserDeleted"
    )
  card(title='Users')
    div.row
      .col-12
        .d-flex.justify-content-end.mb-5
          matador-search#search-box(placeholder='Search Users' autocomplete='off')
          matador-button.ml-3(@click="clickAdd", icon="fa fa-plus")
            | Add User
        keep-alive
          .table-responsive
            bs-table(
              ref="table"
              :columns="table.columns",
              :options="table.options",
              :data="userList",
              @on-post-body="vueFormatterPostBody"
              @on-search="onEvent"
            )
</template>
<script>
import tableMixin from "@/mixins/table";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import UserFormModal from "./UserFormModal.vue";


import entity from './entity'
import {TableOptions} from './table'
import Api from '@/utils/api'


// import {
//   getUsersList,
//   getOrganizationList,
//   getPositionList,
// } from "@/utils/api";


export default {
  mixins: [tableMixin],

  components: {
    ChevronDown,
    ChevronUp,
    EyeOutline,
    EyeOffOutline,
    Plus,
    UserFormModal
  },

  data() {
    const tableOpt = TableOptions(this)

    return {
      organizationList:[],
      position: '',
      currentUser: {},
      showAdd: false,
      userList: [],
      perPage: 10,
      table: {
        options: { ...tableOpt.options, 
          ajax: this.getUsers,
          pageSize: this.perPage,
          searchSelector: "#search-box",
        },
        columns: tableOpt.fields
      }
    };
  },
  


  methods: {

    getUsers(params) {
      
      const responseHandler = ({data, status}) => {
        if (status !== 200)
          throw new Error("Failed to load list user")

        this.userList = data.result.data.map(user => entity.UserListEntity(user))
        let pagination = data.result.pagination
        let resource = {
          rows: this.userList,
          total: pagination.total_data || 0,
          totalNotFiltered: pagination.total_data || 0
        }

        setTimeout(() => params.success(resource), 1000)
      }

      let page = params.data.offset/params.data.limit || 0
      let payload = {
        limit: params.data.limit || 10,
        page: page + 1,
        q: params.data.search,
        sort: `${params.data.sort || 'username'}.${params.data.order || 'asc'}`
      }

      Api.user.list(payload)
        .then(responseHandler)
        .catch(e => {
          let err = e.response ? e.response.data : e
          this.$toast.error(err.message || 'Failed to load user list')
        })
    },


    

    
    clickAdd() {
      this.currentUser = {};
      
      if (this.$store.state.auth.role === "admin") {
        this.currentUser.organization_id = this.$store.state.auth.organization_id
      }

      this.showAdd = true;
    },
    clickEdit(user){
      this.currentUser = user;
      this.showAdd = true;
    },
    clickDelete(user){
      this.currentUser = user;
      this.$refs.form.delete();
    },
    onUserChanged(message, user){
      this.showAdd = false;
      this.$refs.table.refresh();
      this.$toast.success(message || 'Successfully save user data.');
    },
    onUserDeleted(message, user){
      this.$refs.table.refresh();
      this.$toast.success(message || 'Successfully delete user')
    },
    onEvent(event) {
      console.log(event);
    }
  },


  watch: {
    pageSize: function (size)  {
      this.$refs.table.refreshOptions();
    }
  },


  mounted() {
    var self = this;
    window.$('.search input').on('search', function() {
      if(!window.$(this).val()) {
        self.$refs.table.resetSearch();
      }
    });
    
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.content-filter {
  margin-top: -1rem
}
.tab-filter{
  min-width: 200px;
  border-right: 2px solid #EAEAEC;
  padding-right: 0.5rem;
}
.badge-circle {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    padding: 4px;

    text-align: center;

    font: 10pt Arial, sans-serif;
    font: 10pt Arial, sans-serif;
}
.p-viewer{
  float: right;
  margin-right: 10px;
  margin-top: -33px;
  position: relative;
  z-index: 1;
  cursor:pointer;
}
</style>