'use strict'

import dayjs from "dayjs"
import Dropdown from "@/components/Dropdown.vue";


export function tableOptions(self) {

    const dateFormatter = (value, row) => {
      let date = [
        `<div width="90%">`,
        `<div style="display: flex; flex-direction: column;">`,
            `<span style="font-size: .8rem;">s: ${dayjs(row.start_date).format("DD MMM YYYY HH:mm:ss")}</span>`,
            `<span style="font-size: .8rem;">e: ${dayjs(row.end_date).format("DD MMM YYYY HH:mm:ss")}</span>`,
        `</div>`,
        `</div>`
      ]
      return date.join(' ')
    }


    const actionFormatter = (value, row) => {
      return self.vueFormatter({
        template: `<div>
            <dropdown tag="a" class="dropdown-toggle-no-caret" icon="mdi mdi-dots-vertical matador-primary-color h4">
                <li class="p-3 matador-text-03" v-on:click="onEdit(row)">Edit Track</li>
                <li class="p-3 matador-text-03" v-on:click="onDelete(row)">Delete Track</li>
            </dropdown>
            </div>`,
        data: { row, value },
        methods: {
          onEdit: self.clickEdit,
          onDelete: self.clickDelete
        },
        components : {
          Dropdown
        }
      });
    }


    const nameFormatter = (value, row) => {
      return self.vueFormatter({
        template: `
          <div v-on:click="onClick">
            <span>${value}</span>
          </div>`,
        data: { row, value },
        methods: {
          onClick() { self.onClickRow(row) },
        },
        components : {
          Dropdown
        }
      });
    }


    const pointFormatter = (value, row) => {
      let point = `
      <div style="display: flex; flex-direction: column">
        <span>${row.start_point[0]}</span>
        <span>${row.end_point[1]}</span>
      </div>`
      return point
    }


    const options = () => {
        return {
            ajax: self.fetchTrackTableList,
            search: true,
            searchSelector: '#search-button',
            pagination: true,
            showSearchButton: true,
            sortable: true,
            paginationHAlign: "center",
            paginationDetailHAlign: "right",
            totalField: 'total_rows',
            dataField: 'vehicle',
            paginationParts: ["pageList"],
            classes: ["table", "table-hover"],
            loadingFontSize: 16,
            sidePagination: "server"
        }
    }

    const columns = () => {
      return [
        // {
        //   title: ``,
        //   field: "is_view",
        //   checkbox: true,
        //   searchable: false,
        //   sortable: false,
        //   widthUnit: '%',
        //   width: '5'
        // },
        {
          field: "description",
          title: "Vehicle",
          sortable: true,
          formatter: nameFormatter,
          searchable: true,
          widthUnit: '%',
          width: '25'
        },
        {
          field: "start_end_date",
          title: "Start - End Date",
          sortable: true,
          formatter: dateFormatter,
          searchable: true,
          widthUnit: '%',
          width: '25'
        },
        {
          field: "start_end_point",
          title: "Start - End Point",
          sortable: true,
          formatter: pointFormatter,
          align: 'right',
          searchable: true,
          widthUnit: '%',
          width: '15'
        },
        {
          field: "",
          title: "",
          formatter: actionFormatter,
          sortable: false,
          searchable: false,
          widthUnit: '%',
          width: '5'
        }, 
      ]
    }

    return {
        options: options(),
        columns: columns(),
    }
}


