<template lang="pug">
transition(name="modal")
  div
    .modal-mask
      .modal-wrapper
        .modal-dialog(style="width: 520px;max-width: 95%;" role="document")
          .modal-content
          
            .modal-body.p-0
              loader(:loading="loading")
                .d-flex.justify-content-between.border-bottom.py-3.px-4.mt-1
                  h4.h5.m-0.text-dark.font-weight-bold Add Position
                  a.bg-white.border-none(
                    href="javascript:;"
                    role="button",
                    data-dismiss="modal",
                    aria-label="Close"
                    @click="$emit('close')"
                  )
                    div
                      img(:height="14" :width="14" src="@/assets/img/close.png")
                      
                .py-4.my-2.px-4
                  b-form-input(v-model="name" placeholder="Insert Position")

                .py-3.px-4.d-flex.justify-content-end.align-items-center
                  button.btn.btn-link.mr-3(type="button" @click="$emit('close')") Cancel
                  matador-button.px-4.btn-sm( @click="onSave" :disabled="!name") Add
</template>

<script>
import Api from '@/utils/api'

export default {
  
  data() {
    return {
      loading: false,
      name: ''
    }
  },

  methods: {
    onSave() {
      if (this.loading) return

      this.loading = true
      Api.user.create_role({name: this.name})
        .then(({data, status}) => {

          if (status !== 200)
            throw new Error

          let role = data.result.data
          this.$emit('appendRole', role);
          this.$emit('close');
          
        })
        .catch(e => {
          let err = e.response ? e.response.data : e
          this.$toast.error(err.message)
        })
        .finally(() => { 
          this.loading = false 
        })
    }
  }

}
</script>