
const getImage = (status) => {
    let statuses = {
        off: 'off',
        idle: 'idle',
        on: 'on'
    }

    status = statuses[status] || 'off' 
    let icon = require(`@/assets/img/truck-${status}.png`)

    return {
        icon,
        size: [30,30]
    }
}


export default (item) => {
    
    let locations = item.location;
    let result = {
        bounds: new window.google.maps.LatLngBounds(),
        marker: null,
        streetPath: null
    }
    
    
    const drawStreet = streets => {
        result.streetPath = new window.google.maps.Polyline({
            path: streets,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
        });
    }


    const factory = () => {
        let streets = [];
        locations.forEach(loc => {
            streets.push({ lat: loc.lat, lng: loc.lng })
            let pos = new window.google.maps.LatLng(loc.lat, loc.lng)
            result.bounds.extend(pos)
        })

        let [lastLoc] = streets.slice(-1)
        let position = new window.google.maps.LatLng(lastLoc.lat, lastLoc.lng)
        let icon = getImage(item.last_status)
        
       result.marker = new window.google.maps.Marker({
            position,
            icon: {
                url: icon.icon,
                size: new window.google.maps.Size(icon.size[0], icon.size[1]),
            }
        })
        
        result.bounds.extend(result.marker.getPosition())
        return streets
    }

    if (!Array.isArray(locations)) return result

    let streets = factory()
    drawStreet(streets)

    return result
    
      
    // var newMarker = [];
    
    //   var marker, i, j;
    //   for (i = 0; i < newVal.length; i++) {
    //     const locations = newVal[i].location;
    //     if(Array.isArray(locations)) {
    //       let streets = [];

    //       for (j = 0; j < locations.length; j++) {
    //         const location = locations[j];
    //         streets.push({ lat: location.latitude, lng: location.longitude });
    //         this.bounds.extend(new window.google.maps.LatLng(location.latitude, location.longitude ));

    //         // draw icon
    //         if(j === locations.length-1) {
    //           marker = new window.google.maps.Marker({
    //             position: new window.google.maps.LatLng(
    //               location.latitude,
    //               location.longitude
    //             ),
    //             map: this.map,
    //             icon: image[newVal[i].last_status],
    //           });
    //           newMarker.push(marker);
    //           this.bounds.extend(marker.getPosition());
    //         }
    //       }

    //       // draw street
    //       const streetPath = new window.google.maps.Polyline({
    //         path: streets,
    //         geodesic: true,
    //         strokeColor: "#FF0000",
    //         strokeOpacity: 1.0,
    //         strokeWeight: 2,
    //       });

    //       streetPath.setMap(this.map);
    //       newMarker.push(streetPath);
    //     }
    //   }

    //   if(!this.isInit) {
    //     this.map.fitBounds(this.bounds);
    //     this.isInit = true;
    //   }
    //   this.marker = newMarker;
}