<template lang="pug">
div#vehicle-satelitte-detail

  .card.p-3
    .card-header
      div.d-flex.justify-content-between.align-items-center
        h5.font-weight-bold.card-title {{ vehicleNotFound || loading ? '' : title  }}
        button.btn.btn-light(@click="$router.go(-1)") Back
        
    .card-body
      loader(:loading="loading")
        div.row(v-if="vehicleNotFound")
          div.col-lg-12.d-flex.flex-column.justify-content-center.align-items-center(style="min-height: 300px;")
            | Vehicle Not Found

        div.row(v-else)
          div.col-lg-7.mb-3
            google-map#map(
              ref="map"
              :zoom="12"
              :options="mapOptions")
          
          div.col-lg-5
            .card.vehicle-info
              .card-body.p-3.meta-info
                
                .d-flex

                  div.picture-placeholder-wrapper.d-flex
                    img.picture-placeholder(:src="require('@/assets/img/car.png')")

                  .info.px-3
                    .item-info
                      span {{ infos.vehicle_name.label }}
                      span {{ vehicle.vehicle_name }}

                    .item-info
                      span {{ infos.vin.label }}
                      span {{ vehicle.vin }}

                    .item-info
                      span {{ infos.device_name.label }}
                      span {{ vehicle.device_name }}

                div
                  ul.info-in-table
                    li 
                      div.d-flex.justify-content-between.align-items-center
                        span {{ infos.last_update.label }}
                        span {{ lastUpdate }}

                    li 
                      div.d-flex.justify-content-between.align-items-center
                        span {{ infos.last_position.label }}
                        span(v-html="vehicle.last_position")

                div.d-flex.justify-content-between
                  span.custom-btn.relay-status(
                    :disabled="changeStatusLoading"
                    :class="relayStatus ? 'on' : '' "
                    @click="showChangeRelayConfirm = true") 
                    | Relay {{ relayStatus ? 'ON' : 'OFF' }}

                  button.custom-btn.btn.btn-primary(
                    :disabled="disbledGetLatestCondition"
                    @click="onGetLatestCondition")
                      img.mr-2(:src="require('@/assets/img/satellite-icon.png')")
                      span {{ latestCondLabel }}

            .card.vehicle-info
              .card-header(style="padding: 20px 10px") Satellite Log Data Table
              .card-body.p-0
                loader(:loading="historyLoading")
                  table.table.table-sm
                    thead
                      tr
                        th Timestamp
                        th Latitude
                        th Longitude
                        th Opt Time
                    tbody
                      tr(v-for="log in logs")
                        td {{ log.date }}
                        td {{ log.lat }}
                        td {{ log.lng }}
                        td {{ log.opt_time_v2 }}

                div.d-flex.justify-content-end(v-if="!historyLoading")
                  span.d-block.p-3.see-more(@click="goToReport()") See more reports &gt;&gt;
  
  confirmation-modal(
    v-if="showChangeRelayConfirm"
    @close="showChangeRelayConfirm = false" 
    @confirm="onChangedStatus" 
    title="Relay Change State Information"
    confirm-text="Yes"
  )
    p.m-0
      | Are you sure want to change status relay ?  
  
</template>
<script>
import ConfirmationModal from "@/components/Matador/ConfirmationModal";

import entity from "../entity";
import { InitMarker } from "@/utils/map";
import dayjs from "dayjs";
import moment from "moment";
import Api from "@/utils/api";

const icon = require("@/assets/img/driver/truck.png");

export default {
  components: {
    ConfirmationModal,
  },

  data() {
    return {
      prefixTitle: "Satellite Detail",

      infos: {
        vehicle_name: { label: "Vehicle Name" },
        vin: { label: "VIN" },
        device_name: { label: "Device Name" },
        last_update: { label: "Last Data Record" },
        last_position: { label: "Last Position" },
      },

      logs: [],

      loading: false,
      historyLoading: false,
      pendingRequest: false,
      changeStatusLoading: false,
      latestConditionLoading: false,
      title: "",

      showChangeRelayConfirm: false,
      showLatestCondConfirm: false,

      vehicleNotFound: false,
      vehicle: entity.MonitoringListEntity(),
      position: { lat: 0.0, lng: 0.0 },
      relayStatus: false,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
      },
    };
  },

  methods: {
    async getVehicleDetail() {
      if (this.loading) return false;
      this.loading = true;

      const responseHandler = ({ data, status }) => {
        if (status !== 200) throw new Error();

        this.vehicle = entity.DetailEntity(data.result.data);
        this.title = `${this.prefixTitle} - ${this.vehicle.vehicle_name}`;

        // latest condition status buton
        let requestStatus = data.result.data.latest_update_status;
        this.pendingRequest = requestStatus ? requestStatus.status : false;
        let throttle =
          dayjs(requestStatus.updated_at).add(1, "day").unix() * 1000;

        if (Date.now() - throttle) this.pendingRequest = false;

        let position = {
          lat: this.vehicle.latitude,
          lng: this.vehicle.longitude,
        };

        this.relayStatus = this.vehicle.status;

        this.position = position;
        this.vehicle.last_position = `${this.vehicle.latitude},<br />${this.vehicle.longitude}`;

        const self = this;
        initCallback(1000, 10, (done) => {
          const callback = ({ map }) => {
            map.setCenter(self.position);
            setTimeout(() => map.setZoom(map.zoom + 0.5), 1000);
            done();
          };

          InitMarker([self.position], this.$refs.map, callback, icon);
        });
      };

      return Api.vehicle
        .satellite_detail({ vehicle_id: this.$route.params.vehicle_id })
        .then(responseHandler)
        .finally(() => (this.loading = false));
    },

    async getLastLog(loadInMount) {
      if (!loadInMount && this.historyLoading) return;

      this.historyLoading = true;

      let payload = { limit: 10 };
      Api.vehicle
        .satellite_get_history(this.$route.params.vehicle_id, payload)
        .then(({ data, status }) => {
          if (status !== 200) throw new Error();

          this.logs = data.result.data.map((item) =>
            entity.LogSatelliteEntity(item)
          );
        })
        .finally(() => {
          this.historyLoading = false;
        });
    },

    onGetLatestCondition() {
      if (this.pendingRequest || this.latestConditionLoading) return;
      this.latestConditionLoading = true;

      Api.vehicle
        .satellite_latest_condition(this.vehicle_id, {
          imei: this.vehicle.imei,
        })
        .then(({ data, status }) => {
          if (status !== 200) throw new Error();

          this.$toast.success("Request successfully sent");
          this.pendingRequest = true;
        })
        .catch((e) => {
          let error = e.response ? e.response.data : e;
          this.$toast.error(error.message || "Failed to request latest data");
        })
        .finally(() => {
          this.latestConditionLoading = false;
        });
    },

    onChangedStatus() {
      this.showChangeRelayConfirm = false;

      if (this.changeStatusLoading) return;
      this.changeStatusLoading = true;

      let status = !this.relayStatus;
      let payload = {
        status: status ? "true" : "false",
      };

      Api.vehicle
        .satellite_change_state(this.$route.params.vehicle_id, payload)
        .then(({ data, status }) => {
          if (status !== 200) throw new Error();

          this.relayStatus = data.result.data.status;
        })
        .catch((e) => {
          let err = e.response ? e.response.data : e;
          this.$toast.error(err.message || "Failed to change status");
        })
        .finally(() => (this.changeStatusLoading = false));
    },

    goToReport() {
      let vehicle_id = this.$route.params.vehicle_id;
      this.$router.push({ path: `/vehicle_satellites/${vehicle_id}/report` });
    },
  },

  computed: {
    lastUpdate() {
      if (!this.vehicle.last_update) return "-";

      return dayjs(this.vehicle.last_update)
        .add(1, "hour")
        .format("DD/MM/YYYY HH:mm A");
    },

    disbledGetLatestCondition() {
      return this.latestConditionLoading || this.pendingRequest;
    },

    latestCondLabel() {
      if (this.latestConditionLoading) return "Request ...";
      if (this.pendingRequest) return "Waiting for data ...";
      return "Update Latest Status";
    },
  },

  mounted() {
    this.getVehicleDetail().then(() => {
      this.historyLoading = true;
      setTimeout(() => {
        return this.getLastLog(true);
      }, 3000);
    });
  },
};
</script>

<style lang="scss">
@import "styles/detail.scss";
</style>
