<template lang="pug">
loader(:loading="!vehicle")
  .row(v-if="vehicle" @click="closeDatePicker")
      .col-12
        .row
          .col-12
            .card.mb-4.datepicker-container
              .card-body
                .row.py-2.align-items-center
                  .col-12.col-lg-6.col-xl-auto
                    span.h4.m-0 Change all date to : 
                  .col-12.col-lg-6.col-xl-4
                    div(style="position: fixed")
                    datepicker(input-class="form-control datepicker" @selected="onSwitchDate" :value="globalDate" name="globalDate" ref="globalDate")
                    img(style="position: absolute;right: 25px;top: 12px;width: 16px;" src="/icons/chevron-down.png" @click="showCalendar('globalDate')")
        .row
          .col-md-6.col-sm-12.d-flex
            .card.mb-4.flex-grow-1
              .card-body
                .row
                  .col-12
                    img.w-100.matador-border-radius(v-if="vehicle.image" :src="vehicle.image")
                    img.w-100.matador-border-radius(v-if="!vehicle.image" src="@/assets/img/car-placeholder.png")
                    h4.my-5 {{ vehicle.unit_id }}
                    .row(v-for="item in vehicle_data").mb-3
                      .col-6.col-xl-4
                        label {{ item[0] }}
                      .col-6.col-xl-8
                        span {{ item[1] }}
                    .row.mb-3
          .col-md-6.col-sm-12.d-flex
            .card.mb-4.flex-grow-1
              .card-body
                .row
                  .col-12
                    .map
                      #mapDetail(style="min-height: 400px")
                  .col-12.mt-5
                    .border.p-3.matador-border-radius(style="min-height: 400px")
                      .d-flex.justify-content-center.align-items-center
                        a.text-secondary(href="javascript:;" role="button" @click="switchDate(tripDate, -1, 'tripDate')")
                          chevron-left-icon.icon-2x
                        span.mx-3 {{ tripDate | date }}
                        a.text-secondary(href="javascript:;" role="button" @click="switchDate(tripDate, 1, 'tripDate')")
                          chevron-right-icon.icon-2x
                      .d-flex.justify-content-center.align-items-center(v-if="street.length === 0", style="min-height: 400px")
                        h4.matador-text-02 Sorry, we can not found any data in this date
                      .d-flex.trip-container(style="max-height: 21rem")
                        .trip-timeline
                          section.timeline-section.bg-light.py-3.pr-3.pl-5.rounded.border.mt-3(v-for="item in [...trip]")
                            .d-flex
                              p.mb-0.mt-0.mr-auto.pr-3 {{ item.origin }}
                              span.text-muted {{ item.departure_time }}
                            .d-flex.align-items-center.pb-3.pt-2
                              car-icon.icon-2x.mr-3
                              p.pt-1.m-0 
                                | Drive - 
                                span.text-muted {{ item.distance }}
                            .d-flex
                              p.mb-0.mt-0.mr-auto.pr-3 {{ item.destination }}
                              span.text-muted {{ item.arrival_time }}
                          #timeline-line.timeline-line
        .row
          .col-12
            .card.mb-4
              .card-body.p-4
                .row.my-5
                  .col-6.col-md-12.col-xl-6.mb-4
                    chart-card(title="Speed")
                      .my-1.d-flex.justify-content-center.align-items-center(slot="title")
                        span.matador-h4 Speed&nbsp;
                          span.text-unit.font-weight-light (km/h)
                      .my-2.d-flex.justify-content-center.align-items-center(
                        slot="subTitle"
                      )
                        datepicker#speedDatePicker(
                          input-class="invisible position-absolute",
                          @selected="setCurrentDateSpeed",
                          ref="speedDatePicker"
                        )
                        a.text-secondary(
                          href="javascript:;",
                          role="button",
                          @click="switchDate(speedDate, -1, 'speedDate')"
                        )
                          chevron-left-icon.icon-2x
                        a.text-secondary(
                          href="javascript:;",
                          @click="showCalendar('speedDatePicker')"
                        ) 
                          span.mx-3 {{ speedDate | date }}
                        a.text-secondary(
                          href="javascript:;",
                          role="button",
                          @click="switchDate(speedDate, 1, 'speedDate')"
                        )
                          chevron-right-icon.icon-2x
                      .mt-3.w-100.float-right(slot="legend")
                        .d-flex.justify-content-between
                          div
                            i.fa.fa-circle.top-speed-legend
                            |
                            | Top Speed
                            i.fa.fa-circle.avg-speed-legend
                            |
                            | Avarage Speed
                          .btn-group.shadow-sm(role="group")
                            button.btn.btn-sm.btn-switch.text-lowercase(
                              :class="{ active: speedType == 'hourly' }",
                              type="button",
                              @click="speedType = 'hourly'"
                            ) 24h
                            button.btn.btn-sm.btn-switch.text-lowercase(
                              :class="{ active: speedType == 'weekly' }",
                              type="button",
                              @click="speedType = 'weekly'"
                            ) 1w
                            //- button.btn.btn-sm.btn-switch.text-lowercase(:class="{'active': speedType == 'monthly'}" type="button" @click="speedType = 'monthly'") 1m
                      div(slot="chart")
                        bar-chart(
                          :chart-data="speedChart.data",
                          :options="speedChart.options",
                          :stacked="true",
                          :height="200"
                        )
                  .col-6.col-md-12.col-xl-6.mb-4
                    chart-card
                      .my-1.d-flex.justify-content-center.align-items-center(slot="title")
                        span.matador-h4 Fuel Consumption&nbsp;
                          span.text-unit.font-weight-light (L)
                      .my-2.d-flex.justify-content-center.align-items-center(
                        slot="subTitle"
                      )
                        datepicker#fuelDatePicker(
                          input-class="invisible position-absolute",
                          @selected="setCurrentDateFuel",
                          ref="fuelDatePicker"
                        )
                        a.text-secondary(
                          href="javascript:;",
                          role="button",
                          @click="switchDate(fuelDate, -1, 'fuelDate')"
                        )
                          chevron-left-icon.icon-2x
                        a.text-secondary(
                          href="javascript:;",
                          @click="showCalendar('fuelDatePicker')"
                        ) 
                          span.mx-3 {{ fuelDate | date }}
                        a.text-secondary(
                          href="javascript:;",
                          role="button",
                          @click="switchDate(fuelDate, 1, 'fuelDate')"
                        )
                          chevron-right-icon.icon-2x
                      .mt-3.w-100(slot="legend")
                        .btn-group.float-right.shadow-sm(role="group")
                          button.btn.btn-sm.btn-switch.text-lowercase(
                            :class="{ active: fuelType == 'hourly' }",
                            type="button",
                            @click="fuelType = 'hourly'"
                          ) 24h
                          button.btn.btn-sm.btn-switch.text-lowercase(
                            :class="{ active: fuelType == 'weekly' }",
                            type="button",
                            @click="fuelType = 'weekly'"
                          ) 1w
                          //- button.btn.btn-sm.btn-switch.text-lowercase(:class="{'active': fuelType == 'monthly'}" type="button" @click="fuelType = 'monthly'") 1m
                      div(slot="chart")
                        line-chart(
                          :chart-data="fuelChart.data",
                          :options="fuelChart.options",
                          ref="fuelChart",
                          :stacked="true",
                          :height="200",
                          :gradients="['rgba(238, 201, 69, 1)', 'rgba(241, 246, 255, 1)']"
                        )
                .row
                  .col-12
                    .matador-tab.d-flex.vehicle-tab
                      a.mr-5.text-center.py-3(:class="{'active': currentTab == 0}" @click="currentTab = 0" href="javascript:;") Tracking Info
                      a.mr-5.text-center.py-3(:class="{'active': currentTab == 1}" @click="currentTab = 1" href="javascript:;") Engine Info
                      a.mr-5.text-center.py-3(:class="{'active': currentTab == 2}" @click="currentTab = 2" href="javascript:;") Device Info
                    .row
                      .py-2.col-6(v-for="column, index in tabs" :class="{'border-right' : index ===0}")
                        .border-bottom(v-for="item in Object.values(column)")
                          .d-flex.py-3.justify-content-between
                            p.font-weight-bolder.m-0.matador-text-02 {{ item[0] }}
                            p.m-0.text-right {{ item[1] }}
</template>
<script>
import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft.vue";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight.vue";
import CarIcon from "vue-material-design-icons/Car.vue";
import BarChart from "@/components/Chartjs/BarChart";
import LineChart from "@/components/Chartjs/LineChart";
import Datepicker from "vuejs-datepicker";
import dayjs from "dayjs";
import { ChartCard } from "@/components/index";
import { LATITUDE_ASEAN, LONGITUDE_ASEAN, ZOOM_ASEAN } from "@/utils/map";
import { getVehicleDetail, getVehicleSpeed, getFuelConsumtion, getTrip } from "@/utils/api";
export default {
  data() {
    return {
      cols: 2,
      currentTab: 0,
      globalDate: null,
      tripDate: null,
      direction: null,
      speedDate: null,
      fuelDate: null,
      vehicle: null,
      map: null,
      speedType: 'hourly',
      fuelType: 'weekly',
      speedChartData: {
        label: [],
        average: [],
        topSpeed: []
      },
      fuelChartData: {
        label: [],
        total: []
      }
    };
  },
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    CarIcon,
    ChartCard,
    BarChart,
    LineChart,
    Datepicker,
  },
  watch: {
    globalDate: {
      handler: function(newVal){
        this.tripDate = this.speedDate = this.fuelDate = newVal;
      },
      deep: true
    },
    tripDate: {
      handler: function(newVal){
        this.getVehicleDetail(newVal);
        this.getTripDetail(newVal);
      },
      deep: true
    },
    speedDate: {
      handler: function(newVal){
        this.getVehicleSpeed(newVal);
      },
      deep: true
    },
    speedType: {
      handler: function(newVal){
        this.getVehicleSpeed(this.speedDate);
      },
      deep: true
    },
    fuelDate: {
      handler: function(newVal){
        this.getFuelConsumtion(newVal);
      },
      deep: true
    },
    fuelType: {
      handler: function(newVal){
        this.getFuelConsumtion(this.fuelDate);
      },
      deep: true
    },
    street: {
      handler: function(newVal){
        var that = this;

        var checker = setInterval(function () {
          if (window.google) {
            that.renderMap(newVal);
            clearInterval(checker);
          }
        }, 1000);
      },
      deep: true
    }
  },
  methods: {
    closeDatePicker(event){
      // const className = (event.target.className || "");
      // console.log()
      // if(className && className.indexOf('btn up') == -1 && className.indexOf('next') == -1 && className.indexOf('prev') == -1){
      //   this.$refs.speedDatePicker.close();
      //   this.$refs.fuelDatePicker.close();
      // }
    },
    setCurrentDateSpeed(value) {
      this.speedDate = value;
    },
    setCurrentDateFuel(value) {
      this.fuelDate = value;
    },
    showCalendar(ref) {
      setTimeout(this.$refs[ref].showCalendar, 100);
    },
    onSwitchDate(val){
      this.globalDate = val;
    },
    switchDate(current, n, prop){
      const result = dayjs(current).add(n, 'days');
      this[prop] = result;
    },
    onDateTyping(event) {
      event.preventDefault();
    },
    getVehicleDetail(date){
      const trip = dayjs(date).format('YYYY-MM-DD');
      const { unit_id } = this.$route.params;
      getVehicleDetail({ unit_id, trip }).then(({ data: { result } }) => {
        this.vehicle = result;
      });
    },
    getTripDetail(date){
      const trip = dayjs(date).format('YYYY-MM-DD');
      const { unit_id } = this.$route.params;
      getTrip({ unit_id, trip }).then(({ data: { result } }) => {
        this.direction = result;
          setTimeout(this.renderMap, 1000);
      });
    },
    getVehicleSpeed(val){
      const date = dayjs(val).format('YYYY-MM-DD');
      const { unit_id } = this.$route.params;
      const { speedType: type } = this;
      getVehicleSpeed({ date, unit_id, type }).then(({ data: { result } }) => {
        this.speedChartData.label = result.map(item => item.label);
        this.speedChartData.average = result.map(item => parseInt(item.average));
        this.speedChartData.topSpeed = result.map(item => parseInt(item.top_speed));
      });
    },
    getFuelConsumtion(val){
      const date = dayjs(val).format('YYYY-MM-DD');
      const { unit_id } = this.$route.params;
      const { fuelType: type } = this;
      getFuelConsumtion({ date, unit_id, type }).then(({ data: { result } }) => {
        this.fuelChartData.label = result.map(item => item.label);
        this.fuelChartData.total = result.map(item => parseInt(item.total));
      });
    },
    renderMap(newVal) {
      if(!window.google) return;
      if(!this.map) {
        var initLatLng = new window.google.maps.LatLng(
          LATITUDE_ASEAN,
          LONGITUDE_ASEAN
        );
        var mapOptions = {
          zoom: ZOOM_ASEAN,
          scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
          mapTypeControl: true,
          scaleControl: true,
          streetViewControl: false,
          center: initLatLng,
          mapTypeControlOptions: {
            position: window.google.maps.ControlPosition.TOP_RIGHT,
          },
        };

        
        var map = new window.google.maps.Map(
          document.getElementById("mapDetail"),
          mapOptions
        );
        this.map = map;
        this.map.setZoom(ZOOM_ASEAN);
        this.map.panTo(initLatLng);

      }
      // return; 
      if(!newVal || newVal.length === 0) return;

      var marker, i;
      var bounds = new window.google.maps.LatLngBounds();
      let streets = [];
      for (i = 0; i < newVal.length; i++) {
        const locations = newVal[i];
        streets.push({ lat: locations.latitude, lng: locations.longitude });

        bounds.extend({ lat: locations.latitude, lng: locations.longitude });
      }
      const streetPath = new window.google.maps.Polyline({
        path: streets,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      streetPath.setMap(this.map);

      this.map.fitBounds(bounds);
      this.setTimelineHeight();
      
    },
    setTimelineHeight() {      
      var height = window.$('.trip-timeline')[0].scrollHeight;
      height = height - window.$('.timeline-section').last().outerHeight() + 15;
      window.$('#timeline-line').height(height);
    }
  },
  computed: {
    vehicle_data() {
      const { vehicle } = this;
      const device_unit = vehicle.device_unit;
      const log = device_unit.device.last_log_data || {};
      return [
        ["VIN", vehicle.vin],
        ["Device Name", vehicle.device_unit.device.description],
        ["Vehicle Type", vehicle.unit_type.vehicle_type.name],
        log.location_name?["Current Location", log.location_name]:["Current Location", "-"],
      ];
    },
    tabs() {
      if (!this.vehicle) return [];
      const log = this.vehicle.device_unit.device.last_log_data || {};
      let current_info = [];
      if(this.currentTab == 0) {
         current_info= [
          ["Vin", this.vehicle.vin],
          ["Coordinate", log.latitude ? log.latitude + " , " + log.longitude : "-"],
          ["Location", log.location_name ? log.location_name:"-"],
          ["Power Status", log.power_status ? "On" : "Off"],
          ["Operating Status", log.operate_status ? "On" : "Off"],
         /*  ["UUID", this.vehicle.device_unit.device_id], */
          ["Speed",  `${log.speed == undefined ? '-':log.speed+' km/h'} `],
          ["Today Idle Time", `${this.vehicle.device_unit.idle_time==undefined?'-':this.vehicle.device_unit.idle_time+' s'} `],
          ["Today Operating Time", `${this.vehicle.device_unit.opt_time==undefined?'-':this.vehicle.device_unit.opt_time+ ' s' } ` ],
          ["Today Operating Mileage", `${this.vehicle.device_unit.today_mileage} m` ],
          ["Total Time", `${log.total_time==undefined?'-':log.total_time+' s'} `],
          ["Total Mileage (odometer)", `${log.total_mileage==undefined?'-':log.total_mileage+' m'} `],
          ["Total Operating Time", `${log.operate_time==undefined?'-':log.operate_time+'s'} `],
          ["Total Operating Mileage", `${log.operate_mileage==undefined?'-':log.operate_mileage+' m'} `],
        ]
      } else if (this.currentTab == 1) {
        current_info= [
          ["Voltmeter", log.voltage?log.voltage:'-'],
          ["Start Stop Counter", log.start_count?log.start_count:'-'],
          ["Engine", this.vehicle.unit_type.engine],
          ["Tire Count", this.vehicle.unit_type.tire_count],
          ["Torque", this.vehicle.unit_type.torque],
          ["Brand", this.vehicle.unit_type.brand_id?this.vehicle.unit_type.brand_id:'-'],
          ["Series", this.vehicle.unit_type.series],
          ["Year", this.vehicle.unit_type.year],
          ["Fuel", this.vehicle.unit_type.fuel],
        ]
      } else if (this.currentTab == 2) {
        current_info= [
          ["GSM Signal", log.signal?log.signal:'-'],
          ["IMEI", log.imei?log.imei:'-'],
          ["Carrier/Mobile Network", this.vehicle.device_unit.device.network_provider_id],
          ["SIM Number", this.vehicle.device_unit.device.sim_number],
          ["Description", this.vehicle.device_unit.device.description],
          ["Data Usage", log.data_usage?log.data_usage+" MB":'-'],
          ["Device Temperature", log.temperature?log.temperature + " C":'-']
        ]
      }

      let columns = [];
      let mid = Math.ceil(current_info.length / this.cols);
      for (let col = 0; col < this.cols; col++) {
        columns.push(current_info.slice(col * mid, col * mid + mid));
      }
      return columns;
    },
    trip() {
      if(!this.direction) {
        return []
      }
      return this.direction.map(item => {
        const { start, end, direction} = item;

        return {
          origin: start.location_name,
          departure_time: dayjs(start.timestamp).format('HH:mm'),
          distance: direction.distance,
          duration: direction.duration,
          destination: end.location_name,
          arrival_time: dayjs(end.timestamp).format('HH:mm'),
        };
      });
    },
    street() {
      if(!this.direction) {
        return []
      }
      var arrays = this.direction.map(item => {
        const { start, end, direction} = item;

        return direction.trip;
      });
      return [].concat.apply([], arrays);
    },
    speedChart() {
      const { speedChartData: { label, average, topSpeed } } = this;
      return {
        data: {
          labels: label.map(item => Number.isInteger(item) ? item : dayjs(item).format('DD MMM YY')),
          datasets: [
            {
              label: "Average Trip",
              backgroundColor: "#7998B9",
              data: average,
              stack: "speed-stack",
              beginAtZero: true,
            },
            {
              label: "Top Speed",
              backgroundColor: "#FDCA40",
              data: topSpeed,
              stack: "speed-stack",
              beginAtZero: true,
            },
          ],
        },
        options: {
          tooltips: { enabled: false },
          hover: { mode: null },
          legend: {
            display: false,
            onClick: (e) => e.stopPropagation(),
          },
          scales: {
            x: {
              grid: {
                diplay: false
              }
            }
          }
        },
      };
    },
    fuelChart() {
      const { fuelChartData: { label, total } } = this;
      return {
        data: {
          labels: label.map(item => Number.isInteger(item) ? item : dayjs(item).format('DD MMM YY')),
          datasets: [
            {
              label: "Fuel Consumption",
              backgroundColor: "#DDE5ED",
              data: total,
              beginAtZero: true,
              borderColor: "#7998B9",
              borderWidth: 5
            },
          ],
        },
        options: {
          tooltips: { enabled: false },
          hover: { mode: null },
          legend: {
            display: false,
            onClick: (e) => e.stopPropagation(),
          },
          scales: {
            x: {
              grid: {
                diplay: false
              }
            }
          },

          elements: {
            line: {
              tension: 0,
            },
            point:{
                radius: 0
            }
          },
        },
      };
    },
  },
  mounted() {
    this.globalDate = new Date();
    // this.globalDate = new Date('October 20, 2020');
  },
};
</script>
<style lang="scss">
@import "../../../assets/sass/paper/variables.scss";
.datepicker-container {
  z-index: 9999 !important;
}
.trip-container {
  overflow-y: auto;

  &::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
  }
}
.trip-timeline {
  position: relative;
  width: 100%;
  // max-height: 276px;
  > .timeline-line {
    content: " ";
    position: absolute;
    width: 10px;
    background-color: $matador-blue;
    left: 1rem;
    top: 1.5rem;
    bottom: 4%;
    border-radius: 5px;
  }
  > section {
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: #fff;
      left: 16px;
      top: 18px;
      z-index: 1000;
    }
  }

}
.vehicle-tab {
  border-bottom: none !important;
}
</style>
